import React from "react";
import Navbar from "../Navbar";
import Footer from "../Contact";
import "./index.css";
import HomeSlider from "../HomeSlider";
//import { IoMdHome } from "react-icons/io";
import { HelmetProvider, Helmet } from "react-helmet-async";
//import DestinationCard from "../DestinationCard";
import Filter from '../Filter';

const images = [
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-packages%2F1.avif?alt=media&token=a1215e26-6c86-42a9-b5a5-000cca923c3d",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Packages%2F2.jpg?alt=media&token=1ffd52df-f203-4ac1-9ef7-19f18121df95",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Packages%2F3.jpg?alt=media&token=02d3dea4-2d4a-4621-9d37-ba5755c8539d",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Packages%2F4.jpg?alt=media&token=12a81737-93e8-4b22-87d4-622a63322dc1",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Packages%2F6.jpg?alt=media&token=8c3b29c2-6215-4cc0-8a72-4f947eea3c2b",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-packages%2F8.webp?alt=media&token=e823c90f-b626-4f7c-acee-f662c61a3c39",
]

const HotSellingPackages = () => {

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Curated Journeys by Travel Unbounded</title>
          <meta
            name="description"
            content="Discover extraordinary curated journeys with Travel Unbounded. Book Now for an immersive experience"
          />
        </Helmet>
        <Navbar />
        <HomeSlider images={images} showOverlayText={false} />
        <h1 className="text-center wildlife-package-head mt-5 mb-3">
          LEISURE PACKAGES
        </h1>
       <Filter/>
        <Footer />
      </HelmetProvider>
    </>
  );
};

export default HotSellingPackages;
