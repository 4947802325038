import React from "react";
import "./privacy.css";

const Privacy = () => {
  return (
    <div>
      <h2 className="text-center mt-5 mb-5">Privacy Policy</h2>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 col-xl-12">
            <p className="mb-3">
              We are committed to ensuring that your privacy is protected.
              Should we ask you to provide certain information by which you can
              be identified when using this website, and then you can be assured
              that it will only be used in accordance with this privacy
              statement.
            </p>
            <p className="mb-3">
              TRAVELUNBOUNDED WORLD PRIVATE LIMITED may change this policy from
              time to time by updating this page. You should check this page
              from time to time to ensure that you are happy with any changes.
            </p>
            <ul>
              <li className="mb-3">What We Collect</li>
              <li className="mb-3">
                We may collect the following information:
              </li>
              <li className="mb-3">name and job title</li>
              <li className="mb-3">
                contact information including email address
              </li>
              <li className="mb-3">
                demographic information such as postcode, preferences and
                interests
              </li>
              <li className="mb-3">
                other information relevant to customer surveys and/or offers
              </li>
              <li className="mb-3">
                What we do with the information we gather
              </li>
            </ul>
            <p className="mb-3">
              We require this information to understand your needs and provide
              you with a better service, and in particular for the following
              reasons:
            </p>
            <ul>
              <li className="mb-3">Internal record keeping.</li>
              <li className="mb-3">
                We may use the information to improve our products and services.
              </li>
              <li className="mb-3">
                We may periodically send promotional emails about new products,
                special offers or other information which we think you may find
                interesting using the email address which you have provided.
              </li>
              <li className="mb-3">
                From time to time, we may also use your information to contact
                you for market research purposes. We may contact you by email,
                phone, fax or mail. We may use the information to customise the
                website according to your interests.
              </li>
            </ul>
            <h4 className="mb-3 mt-5">Security</h4>
            <p className="mb-3">
              We are committed to ensuring that your information is secure. In
              order to prevent unauthorised access or disclosure we have put in
              place suitable physical, electronic and managerial procedures to
              safeguard and secure the information we collect online.
            </p>
            <h4 className="mb-3 mt-5">How we use cookies</h4>
            <p className="mb-3">
              A cookie is a small file which asks permission to be placed on
              your computer&#39;s hard drive. Once you agree, the file is added
              and the cookie helps analyze web traffic or lets you know when you
              visit a particular site. Cookies allow web applications to respond
              to you as an individual. The web application can tailor its
              operations to your needs, likes and dislikes by gathering and
              remembering information about your preferences.
            </p>
            <p className="mb-3">
              We use traffic log cookies to identify which pages are being used.
              This helps us analyse data about webpage traffic and improve our
              website in order to tailor it to customer needs. We only use this
              information for statistical analysis purposes and then the data is
              removed from the system.
            </p>
            <p className="mb-3">
              Overall, cookies help us provide you with a better website, by
              enabling us to monitor which pages you find useful and which you
              do not. A cookie in no way gives us access to your computer or any
              information about you, other than the data you choose to share
              with us.
            </p>
            <p className="mb-3">
              You can choose to accept or decline cookies. Most web browsers
              automatically accept cookies, but you can usually modify your
              browser setting to decline cookies if you prefer. This may prevent
              you from taking full advantage of the website.
            </p>
            <h4 className="mb-3 mt-5">Controlling your personal information</h4>
            <p className="mb-3">
              You may choose to restrict the collection or use of your personal
              information in the following ways:
            </p>
            <p className="mb-3">
              whenever you are asked to fill in a form on the website, look for
              the box that you can click to indicate that you do not want the
              information to be used by anybody for direct marketing purposes
            </p>
            <p className="mb-3">
              if you have previously agreed to us using your personal
              information for direct marketing purposes, you may change your
              mind at any time by writing to or emailing us at
              support@travelunbounded.com
            </p>
            <p className="mb-3">
              We will not sell, distribute or lease your personal information to
              third parties unless we have your permission or are required by
              law to do so. We may use your personal information to send you
              promotional information about third parties which we think you may
              find interesting if you tell us that you wish this to happen.
            </p>
            <h4 className="mb-3 mt-5">Website disclaimer</h4>
            <p className="mb-3">
              The information contained in this website is for general
              information purposes only and while we endeavor to keep the
              information up to date and correct, we make no representations or
              warranties of any kind, express or implied, about the
              completeness, accuracy, reliability, suitability or availability
              with respect to the website or the information, products,
              services, or related graphics contained on the website for any
              purpose. Any reliance you place on such information is therefore
              strictly at your own risk.
            </p>
            <p className="mb-3">
              In no event will we be liable for any loss or damage including
              without limitation, indirect or consequential loss or damage, or
              any loss or damage whatsoever arising from loss of data or profits
              arising out of, or in connection with, the use of this website.
            </p>
            <p className="mb-3">
              Through this website you are able to link to other websites which
              are not under the control of this business. We have no control
              over the nature, content and availability of those sites. The
              inclusion of any links does not necessarily imply a recommendation
              or endorse the views expressed within them.
            </p>
            <p className="mb-3">
              Every effort is made to keep the website up and running smoothly.
              However, we takes no responsibility for, and will not be liable
              for, the website being temporarily unavailable due to technical
              issues beyond our control.
            </p>
            <p className="mb-5">
              Cancellation &amp; Refund Policy: Cancellation and Refund policy
              depends on the hotels and resorts where the reservation has been
              made, and it varies from hotel to a hotel, tour to tour and
              factors such as the seasonality, duration between the date of
              cancellation and the check in date, etc.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Privacy;
