import React, {useState, useEffect} from 'react';
import Navbar from '../Navbar';
import Footer from '../Contact';
import HomeSlider from '../HomeSlider';
import {Link} from 'react-router-dom';
import firebase from "firebase/compat/app";
import BaliOffer from "../BaliOffer";
import "firebase/compat/firestore";
import { FaHotel } from "react-icons/fa6";
import { LuPackagePlus } from "react-icons/lu";
import { MdFamilyRestroom } from "react-icons/md";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import Callback from '../Callback';
import Faq from '../Faq';
import './index.css';

const faqData = [
  {
    question: "1. When is the best time to visit Bali?",
    answer: `The best time to visit Bali is during the dry season, which runs from April to October. This period offers sunny days, less humidity, and calm seas, making it ideal for outdoor activities like beach outings, hiking, and water sports.`
  },
  {
    question: "2. Do I need a visa to visit Bali?",
    answer: `For most nationalities, Bali offers a visa-free entry for visits up to 30 days for tourism purposes. If you wish to stay longer, you can apply for a Visa on Arrival (VoA), which allows for a 30-day stay and can be extended for another 30 days. Check the latest visa policies before traveling.`
  },
  {
    question: "3. What is the currency used in Bali, and how can I exchange money?",
    answer: `The official currency of Bali is the Indonesian Rupiah (IDR). You can exchange money at banks, authorized counters, and hotels. Use reputable centers to avoid scams, and check exchange rates beforehand. Credit cards are widely accepted at major establishments.`
  },
  {
    question: "4. What are the must-visit attractions in Bali?",
    answer: `Bali offers incredible attractions, including:<br>
             - <strong>Uluwatu Temple:</strong> Stunning sunsets.<br>
             - <strong>Tegallalang Rice Terraces:</strong> Iconic cultural views.<br>
             - <strong>Monkey Forest Sanctuary:</strong> Unique wildlife experiences.<br>
             - <strong>Mount Batur:</strong> Sunrise trekking adventures.<br>
             - <strong>Nusa Penida Island:</strong> Pristine beaches and snorkeling.`
  },
  {
    question: "5. What activities are available for adventure seekers in Bali?",
    answer: `Adventure lovers can enjoy:<br>
             - Surfing at beaches like Kuta and Uluwatu.<br>
             - Scuba diving and snorkeling at Amed and Tulamben.<br>
             - Trekking Mount Batur or Mount Agung.<br>
             - White-water rafting on Ayung and Telaga Waja rivers.<br>
             - Paragliding at Timbis Beach.`
  },
  {
    question: "6. Is Bali a family-friendly destination?",
    answer: `Yes! Bali offers family-friendly activities like water parks, cultural performances, and wildlife sanctuaries. Many resorts provide kids' clubs and babysitting services for a relaxing family vacation.`
  },
  {
    question: "7. What cultural customs should I be aware of when visiting Bali?",
    answer: `Respect traditions by dressing modestly for temple visits, covering shoulders and knees, and wearing a sarong and sash. Avoid stepping on ground offerings, and be mindful of pointing gestures.`
  },
  {
    question: "8. How can I get around Bali?",
    answer: `Transportation options include taxis, motorbike rentals, ride-hailing apps like Gojek and Grab, and hiring private drivers. Renting a motorbike is popular but requires caution due to traffic conditions.`
  },
  {
    question: "9. What should I pack for my trip to Bali?",
    answer: `Pack light, breathable clothing, swimwear, sun protection (hat, sunscreen), and comfortable walking shoes. Bring a sarong for temple visits and insect repellent, especially during the rainy season.`
  },
  {
    question: "10. Are there any health precautions I should take before visiting Bali?",
    answer: `Consult a healthcare professional for recommended vaccinations like typhoid, hepatitis A and B, and tetanus. Avoid drinking tap water; opt for bottled or filtered water. Use mosquito repellent.`
  },
  {
    question: "11. Is Bali safe for solo travelers?",
    answer: `Yes, Bali is safe for solo travelers with a welcoming atmosphere. Stay vigilant, avoid poorly lit areas at night, and secure your belongings.`
  },
  {
    question: "12. What traditional Balinese dishes should I try?",
    answer: `Don't miss:<br>
             - <strong>Nasi Goreng:</strong> Fried rice.<br>
             - <strong>Mie Goreng:</strong> Fried noodles.<br>
             - <strong>Babi Guling:</strong> Suckling pig.<br>
             - <strong>Satay Lilit:</strong> Minced seafood satay.<br>
             - <strong>Lawar:</strong> Spicy meat salad.`
  },
  {
    question: "13. Can I use my phone and access the internet easily in Bali?",
    answer: `Yes, Bali offers good mobile coverage and Wi-Fi in cafes, hotels, and restaurants. Consider buying a local SIM card for affordable data plans.`
  },
  {
    question: "14. What souvenirs can I bring back from Bali?",
    answer: `Popular options include Balinese jewelry, wood carvings, batik textiles, artwork, and essential oils. Support local artisans by shopping at markets.`
  },
  {
    question: "15. Are there any etiquette rules for tipping in Bali?",
    answer: `Tipping is appreciated but not mandatory. In restaurants, a 5-10% tip is common. Guides, drivers, and other services also welcome small tokens of appreciation.`
  }
];


const images = [
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/bali%2Fkelingking-beach-sunset-nusa-penida-island-bali-indonesia.webp?alt=media&token=68f3b9ad-7720-4302-96d4-243c690449ac",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/bali%2Fbali.jpg?alt=media&token=33b41f3e-49db-47a6-b22f-75f6bb394399",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/bali%2Fbali-7242256_1280.webp?alt=media&token=a9f32bdb-a242-40c7-a881-ae3d415de83a",
]

const DestinationBali = () => {
    const [packages, setPackages] = useState([]);
    const [showModal, setShowModal] = useState(true);

    const handleCloseModal = () => {
      setShowModal(false);
    };

    useEffect(() => {
        const fetchPackages = async () => {
          const db = firebase.firestore();
          try {
            const snapshot = await db.collection("PackageTemplate").get();
            const packageList = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setPackages(packageList);
          } catch (error) {
            console.error("Error fetching packages:", error);
          } 
        };
    
        fetchPackages();
      }, []);

      const keralaPackages = packages.filter(pkg => 
        pkg.destinationName.toLowerCase().includes('bali')
      );
    
    return (
        <>
         <HelmetProvider>
         <Helmet>
            <title>Bali tour packages flat 10% off - Book now !</title>
            <meta
                name="description"
                content="Best selling Bali tour packages up to 10% off  starting @ just $251. Book now with Travelunbounded"
                />
            </Helmet>
            <Navbar/>
            <HomeSlider
             images={images}
             showOverlayText={true}
             overlayTitle="Majestic Bali Getaway"
            overlayPrice="USD 251"
            originalPrice="USD 276"
            buttonText="Avail Now"
             />
             <Callback/>
              {showModal && (
                <div className="modal-overlay-offer">
                  <div className="modal-content-offer">
                    <div className="modal-header-offer">
                      <button onClick={handleCloseModal} className="close-button">✖</button>
                      <p>Biggest Price Drop Sale is Live</p>
                      <h3 className='blinking-price-offer'>Flat 10% OFF Book Now</h3>
                    </div>
                    <div className="modal-image" style={{ backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/enquire%2Foffer-1.webp?alt=media&token=a4cb3585-679a-416e-ac88-ec8b49b19ff4)' }}>
                      <div className="modal-facilities">
                        <div className="facility">
                          <FaHotel size={30} className="facility-icon"/>
                          <p>Handpicked, High-Quality Hotels</p>
                        </div>
                        <div className="facility">
                        <LuPackagePlus size={30} className="facility-icon"/>
                          <p>Perfectly Tailored Itineraries</p>
                        </div>
                        <div className="facility">
                        <MdFamilyRestroom size={30} className="facility-icon"/>
                          <p>Unforgettable Family Vacations</p>
                        </div>
                      </div>
                    </div>
                    <div className="modal-buttons">
                      <button onClick={handleCloseModal} className="dismiss-button">Dismiss</button>
                      <a href="https://wa.me/918792273808" target="__blank" aria-label="WhatsApp">
                        <button className="chat-now-button">Chat Now</button>
                      </a>
                    </div>
                  </div>
                </div>
              )}
            <BaliOffer/>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-12 col-lg-12 col-xl-12 bali-container mt-3'>
                     <Link to='/package/leisure/bali' className='link'><h1 className='blinking-price'>Discover the Magic of Bali: A Comprehensive Guide to the Island of the Gods</h1></Link>
                     <p>Bali is a captivating island in Indonesia, known for its stunning natural beauty, vibrant culture, and exceptional hospitality. Whether you're seeking a serene beach escape, an adventure-filled journey, or a spiritual retreat, Bali offers an unparalleled blend of tropical landscapes, cultural experiences, and modern amenities, making it an ideal destination for travelers and a magical place to live.</p>
                     <h2>Geography of Bali: A Tropical Wonderland</h2>
                     <p>Bali’s geography is diverse, featuring volcanic mountains, terraced rice paddies, and pristine beaches. Located between Java and Lombok, Bali is part of the Indonesian archipelago and is surrounded by the Indian Ocean and Bali Sea, offering travelers a variety of stunning natural settings, from lush rainforests to clear waters perfect for snorkeling and diving.</p>
                     <h2>Airports in Bali: Gateway to Paradise</h2>
                     <p>Bali is easily accessible for international and domestic travelers, with one major international airport serving as the island’s primary travel hub.</p>
                     <ul>
                        <li><b>Ngurah Rai International Airport (DPS):</b><br/>Also known as Denpasar International Airport, Ngurah Rai is the main gateway to Bali. It connects the island to major international destinations across Asia, Europe, and Australia, making it one of the busiest airports in Indonesia. The airport has been modernized to provide world-class facilities and services, ensuring a seamless experience for travelers.</li>
                     </ul>
                     <h2>Climate of Bali: Year-Round Tropical Bliss</h2>
                     <p>Bali enjoys a tropical climate with distinct wet and dry seasons. The wet season typically lasts from November to March, while the dry season runs from April to October, making the latter an ideal time for tourists to explore the island's outdoor activities. The weather is generally warm and humid year-round, with temperatures ranging between 25-30°C, perfect for beach lovers and adventure seekers alike.</p>
                     <h2>Culture of Bali: A Rich Tapestry of Traditions</h2>
                     <p>Balinese culture is deeply spiritual and artistic, influenced by Hinduism and centuries-old traditions. The island is famous for its traditional dance forms, like the Barong and Legong dances, and its colorful festivals, such as Galungan and Nyepi (the Balinese Day of Silence). Bali’s handicrafts, music, and cuisine reflect the richness of its culture, offering visitors an immersive and authentic experience.</p>
                     <h2>Visa Information for Foreign Nationals</h2>
                     <p>International visitors to Bali should ensure they meet visa requirements before traveling. Several countries are eligible for a visa-on-arrival, but it's best to check specific details to ensure smooth entry into Indonesia.</p>
                     <h2>Unique Bali Experiences: Top Destinations to Explore</h2>
                     <p>Bali offers a range of experiences that cater to every traveler’s taste, from spiritual retreats to vibrant nightlife and thrilling adventures. Here are some must-visit destinations that showcase the essence of Bali.</p>
                     <h3>1. Bali's Iconic Beaches</h3>
                     <ul>
                        <li><b>Kuta Beach:</b> One of Bali's most famous beaches, Kuta is known for its lively atmosphere, long sandy shorelines, and fantastic surfing conditions. It's a hub for beachgoers, nightlife enthusiasts, and water sports lovers.</li>
                        <li><b>Seminyak Beach:</b> Offering a more upscale experience, Seminyak boasts luxury resorts, chic beach clubs, and trendy restaurants. It’s the perfect spot for a sunset drink or a day of relaxation.</li>
                        <li><b>Nusa Dua:</b> A quieter, more family-friendly option, Nusa Dua is known for its pristine beaches, luxury resorts, and crystal-clear waters, making it ideal for swimming and snorkeling.</li>
                        <li><b>Padang Padang:</b> A favorite among surfers, Padang Padang is a hidden gem with dramatic rock formations and excellent waves. Its secluded atmosphere is perfect for those looking to escape the crowds.</li>
                     </ul>
                     <h3>2. Bali’s Lush Upland and Rice Terraces </h3>
                     <ul>
                        <li><b>Ubud:</b> Known as the cultural heart of Bali, Ubud is famous for its verdant rice terraces, monkey forests, and vibrant art scene. Key attractions include the Tegallalang Rice Terraces, Ubud Palace, and the Sacred Monkey Forest Sanctuary.</li>
                        <li><b>Jatiluwih Rice Terraces:</b> Recognized as a UNESCO World Heritage site, these expansive rice fields are a marvel of Balinese agricultural engineering, offering breathtaking views and serene walks through nature.</li>
                     </ul>
                     <h3>3. Adventure and Nature in Bali</h3>
                     <ul>
                        <li><b>Mount Batur:</b> For adventure enthusiasts, a sunrise trek to the summit of Mount Batur is a must. This active volcano offers stunning panoramic views of the surrounding lakes and mountains.</li>
                        <li><b>Tegenungan Waterfall:</b> Located near Ubud, Tegenungan Waterfall is one of Bali’s most popular natural attractions. Visitors can swim in the refreshing pools or hike along the riverbank to explore the area.</li>
                        <li><b>Menjangan Island:</b> For divers and snorkelers, Menjangan Island offers some of Bali’s best underwater experiences. The crystal-clear waters are home to vibrant coral reefs and diverse marine life.</li>
                     </ul>
                     <h3>4. Spiritual and Historical Landmarks</h3>
                     <ul>
                        <li><b>Uluwatu Temple:</b> Perched on a cliff overlooking the Indian Ocean, Uluwatu Temple is one of Bali’s most iconic spiritual sites. It's a perfect spot to watch the sunset while experiencing a traditional Kecak dance performance.</li>
                        <li><b>Tirta Empul Temple:</b> Located near Ubud, Tirta Empul is a sacred water temple where visitors can partake in a traditional purification ritual in the holy springs.</li>
                        <li><b>Besakih Temple:</b> Known as the “Mother Temple of Bali,” Besakih is the largest and holiest temple complex on the island, located on the slopes of Mount Agung.</li>
                     </ul>
                     <h3>5. Wellness and Relaxation in Bali </h3>
                     <ul>
                        <li><b>Yoga and Retreats in Ubud:</b> Bali is a global hub for wellness, and Ubud is the center of it all. Visitors can indulge in yoga retreats, meditation sessions, and holistic treatments at renowned wellness centers like the Yoga Barn and the Como Shambhala Estate.</li>
                        <li><b>Spas in Seminyak and Uluwatu:</b> Bali is home to some of the best luxury spas in the world, offering a range of rejuvenating treatments using traditional Balinese methods.</li>
                     </ul>
                     <h3>6. Local Markets and Handicrafts</h3>
                     <ul>
                        <li><b>Ubud Art Market:</b> A haven for art lovers, the Ubud Art Market offers a variety of handicrafts, textiles, and souvenirs. Visitors can find everything from handmade jewelry to traditional paintings.</li>
                        <li><b>Sukawati Art Market:</b> Located in Gianyar, this bustling market is one of the best places to shop for local crafts, including wood carvings, textiles, and paintings.</li>
                     </ul>
                     <h3>7. Bali’s Vibrant Nightlife</h3>
                     <ul>
                        <li><b>Beach Clubs in Canggu:</b> For those looking for a lively nightlife scene, Canggu is home to some of Bali’s trendiest beach clubs, including Finns Beach Club and La Brisa. Enjoy live music, sunset parties, and oceanfront dining.</li>
                        <li><b>Seminyak’s Bar and Club Scene:</b> Seminyak is renowned for its sophisticated nightlife, with rooftop bars, cocktail lounges, and world-class restaurants offering late-night entertainment.</li>
                     </ul>
                     <h1>Frequently Asked Questions(FAQ's)</h1>
                     <Faq faqData={faqData}/>
                    </div>
                </div>
            </div>
            <div>
                <p className='kerala-destination-head mb-3 mt-5'>View Bali travel packages</p>
                    <div className="destinations-container mb-5">
                    <div className="best-package-list">
                    {keralaPackages.map((pkg) => (
                        <div key={pkg.id} className="package-list-card mb-3">
                        <img
                            src={pkg.bannerImageUrl}
                            alt={pkg.destinationName}
                            className="list-card-img mb-3"
                        />
                        <div>
                            <p className="title-list mb-3">{pkg.destinationHead}</p>
                            <p className="title-dates-list bold mb-3">{pkg.noOfDays}</p>
                            <p className='mt-3 package-template-price'>
                            
                                <span style={{paddingLeft:'10px'}}>
                                <b><span className="blinking-price">${pkg.newCost}</span></b> 
                                <span className="linethrough-price"> ${pkg.originalPrice}</span>
                                <span style={{marginLeft:'5px'}}>PP</span>
                                </span>
                            
                            </p>
                            <div className="btn-container-list-card">
                            <Link
                                to={`/package/leisure/${pkg.destinationName.replace(/\s+/g, "-")}`}
                                className="blogs-link"
                            >
                                <button className="view-button-list-card">
                                View Itinerary
                                </button>
                            </Link>
                            <Link to="/form" className="blogs-link">
                                <button className="view-button-list-card-book">
                                Book Now
                                </button>
                            </Link>
                            </div>
                        </div>
                        </div>
                    ))}
                    </div>
                </div>
            </div>
            <Footer/>
            </HelmetProvider>
        </>
    );
}

export default DestinationBali;
