import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './index.css';

const testimonials = [
  {
    name: "Preethi Ramani",
    initials: "PR",
    review: "We had the trip of a lifetime in Kenya thanks to the Travel Unbounded team. The hotels & camps they selected for us were top notch and felt unique, intimate and not at all touristy. Their guides were great and gave us some amazing wildlife sightings. They were very patient and accommodating with young kids, elderly parents and a large group with diverse needs. Looking forward to our next trip with them !",
    rating: 5,
  },
  {
    name: "Sourabh C",
    initials: "SC",
    review: "Traveling to Africa and specifically Masai Mara was a bucket list trip for me and my family (wife and teenage son) for many years. After years of procrastination, we finally managed to book the experience with help from Travel Unbounded and Chandrashekar planning the trip for us from 01-Aug to 06-Aug. It was a fantastic trip right from the careful planning for flight and stay across 3 quality resorts at Lake Nakuru and Masai Mara, optimizing the activities for the days, food - picnic/packed or sit down gourmet ones and safety and precautions for the trip.",
    rating: 5,
  },
  {
    name: "Aishwarya Narasimhan",
    initials: "AN",
    review: "To say that this tour was a breathtaking experience is nothing short of a criminal understatement that severely falls short of describing the true beauty of the Masai Mara Reserve. The memories that this place leaves behind are ones worth cherishing for a thousand lifetimes. Right from the lush plains of the savannah to the dazzling beauty of the countless animals it’s a journey worth every rupee spent.",
    rating: 5,
  },
  {
    name: "Satish BillaKota",
    initials: "SB",
    review: "Had an unforgettable 6-night wildlife adventure at Jim Corbett with TravelUnbound. Exceptional planning covered everything from travel, food to comfortable forest rest house stays. Chandru, our host, was outstanding with his deep wildlife knowledge, kind heart, and big smile, enhancing our experience with warm hospitality. The trip was stress-free, comfortable and relaxing. We spotted tigers, leopards, tuskers, martins, rare birds, and more. In addition to the incredible wildlife, we made new friends within the group. With 14 years of wildlife tours under our belt, this was by far the best experience our family had.",
    rating: 5,
  },
  {
    name: "Anitha Badami",
    initials: "AB",
    review: "My family of 6 (including two seniors and a 4yr old) just completed a wonderful trip of Vietnam and Cambodia (July 2024) with Travel Unbounded. In one word, I should say it was “Awesome”. As we planned this tour unexpectedly, I did not have much time to research about the places of interest in these two countries. Given the time constraints owing to visa approval times and flight ticket price rising every day, I decided to approach Mr. Chandru. The very next day, he provided us an itinerary filled with activities and places, many unknown to me! Glancing through it quickly, we went ahead with our bookings.",
    rating: 5,
  },
];


const TestimonialCarousel = () => {
  const [expandedIndices, setExpandedIndices] = useState([]);

  const toggleExpand = (index) => {
    setExpandedIndices((prevState) =>
      prevState.includes(index)
        ? prevState.filter((i) => i !== index)
        : [...prevState, index]
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="testimonial-carousel">
      <h1 className="home-head text-center mb-3">TESTIMONIALS</h1>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-card">
            <div
              className={`testimonial-review ${
                expandedIndices.includes(index) ? "expanded" : ""
              }`}
            >
              {testimonial.review}
            </div>
            <div
              className="view-more"
              onClick={() => toggleExpand(index)}
            >
              {expandedIndices.includes(index) ? "View Less" : "View More"}
            </div>
            <div className="testimonial-info">
              <div className="testimonial-initials">
                {testimonial.initials}
              </div>
              <div className="testimonial-rating">
                {"★".repeat(Math.floor(testimonial.rating))}
                {testimonial.rating % 1 !== 0 && "☆"}
              </div>
              <div className="testimonial-name">
                {testimonial.name}
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TestimonialCarousel;
