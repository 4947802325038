import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { useParams } from "react-router-dom";
import { MdDateRange, MdAccountCircle } from "react-icons/md";
import Navbar from "../Navbar";
import Contact from "../Contact";
import './index.css';

const CustomerBlogDetails = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      const db = firebase.firestore();
      const blogDoc = await db.collection("CustomerBlogs").doc(id).get();
      if (blogDoc.exists) {
        setBlog(blogDoc.data());
      }
    };

    fetchBlog();
  }, [id]);

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate();
    return date.toLocaleDateString();
  };

  if (!blog) {
    return <div className='loading-container'>
      <p className='loading'></p>
    </div>;
  }

  return (
    <>
      <Navbar />
      <div className="full-blog-container">
        <div className="full-blog">
          <img src={blog.imageUrl} alt={blog.title} className="full-blog-image" />
          <h1>{blog.title}</h1>
          {blog.paragraphs.map((para, index) => (
            <div key={index}>
              {para.heading && <h4><b>{para.heading}</b></h4>}
              <p>{para.text}</p>
            </div>
          ))}
          <div className="blog-row">
            <div className="blog-col">
              <p><b>Posted on</b> <br /><MdDateRange size={15} /> {formatDate(blog.createdAt)}</p>
            </div>
            <div>
              <p className="blog-powered-text">Powered by <br /> Travel Unbounded</p>
            </div>
            <div className="blog-col">
              <p><b>Created by</b> <br /><MdAccountCircle size={15} /> {blog.customerName}</p>
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </>
  );
};

export default CustomerBlogDetails;
