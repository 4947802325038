import React, { useState } from 'react';
import './index.css';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { toast, Toaster } from "react-hot-toast";

const AddQuiz = () => {
    const [q1, setQuestion] = useState('');
    const [responses, setResponseNumber] = useState('');
    const [winner, setLastWeekWinner] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        const db = firebase.firestore();

        await db.collection('Quiz').doc('data').update({
            q1,
            responses: Number(responses),
            winner,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        });

        toast.success('Quiz added successfully!');
        
        setQuestion('');
        setResponseNumber('');
        setLastWeekWinner('');
    };

    return (
        <div className="add-quiz-container">
            <Toaster toastOptions={{ duration: 5000 }} style={{ fontStyle: 'Canva Sans', color: 'black' }} />
            <form className="add-quiz-form" onSubmit={handleSubmit}>
                <h2 className="add-quiz-title">Add Quiz</h2>
                <div className="quiz-form-group">
                    <label className="quiz-form-label">Question:</label>
                    <input 
                        type="text" 
                        className="quiz-form-input" 
                        value={q1} 
                        onChange={(e) => setQuestion(e.target.value)} 
                        required 
                    />
                </div>
                <div className="quiz-form-group">
                    <label className="quiz-form-label">Random Response Number:</label>
                    <input 
                        type="number" 
                        className="quiz-form-input" 
                        value={responses} 
                        onChange={(e) => setResponseNumber(e.target.value)} 
                    />
                </div>
                <div className="quiz-form-group">
                    <label className="quiz-form-label">Last Week's Winner:</label>
                    <input 
                        type="text" 
                        className="quiz-form-input" 
                        value={winner} 
                        onChange={(e) => setLastWeekWinner(e.target.value)} 
                    />
                </div>
                <button type="submit" className="quiz-submit-button">Add Quiz</button>
            </form>
        </div>
    );
}

export default AddQuiz;
