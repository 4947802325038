import React, {useState, useEffect} from 'react';
import Navbar from '../Navbar';
import Footer from '../Contact';
import HomeSlider from '../HomeSlider';
import {Link} from 'react-router-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import './index.css';

const images = [
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/himachal%2Fnew.webp?alt=media&token=bdc9ddeb-e1a6-4297-959d-dbc5571e4727",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/himachal%2Fnew-2.jpg?alt=media&token=875a6a54-478f-4e5d-a64a-d8c7eead774e",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Home%2Fhimachal-3647248_1920.jpg?alt=media&token=55a05050-e090-402c-8aa4-d8912a081fcf",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/himachal%2Fpexels-winson-mathew-480421-1205230.webp?alt=media&token=467b5128-fbf7-4c56-92c0-ad9b8ed10b2a",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/himachal%2Fnew-4.webp?alt=media&token=5880b486-0c31-43d0-8ba7-d1f96e9edc43",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/packageTemplate%2FManali%20copy.jpg?alt=media&token=a0af0bcd-2a99-4666-bfbb-2407a29ab20b"
]

const DestinationHimachal = () => {
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        const fetchPackages = async () => {
          const db = firebase.firestore();
          try {
            const snapshot = await db.collection("PackageTemplate").get();
            const packageList = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setPackages(packageList);
          } catch (error) {
            console.error("Error fetching packages:", error);
          } 
        };
    
        fetchPackages();
      }, []);

      const himachalPackages = packages.filter(pkg => 
        pkg.destinationName.toLowerCase().includes('spiti') || 
        pkg.destinationName.toLowerCase().includes('lahaul') ||
        pkg.destinationName.toLowerCase().includes('shimla')
      );

    return (
        <div>
        <HelmetProvider>
        <Helmet>
            <title>Discover the Enchanting Beauty of Himachal Pradesh - Top Travel Destinations & Experiences</title>
            <meta
                name="description"
                content="Explore the serene landscapes of Himachal Pradesh, from majestic hill stations to adventure-packed activities. Discover top destinations like Shimla, Manali, Dharamshala, and more. Plan your perfect Himachal getaway today!"
                />
            </Helmet>
            <Navbar/>
            <HomeSlider images={images}/>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-12 col-lg-12 col-xl-12 himachal-container mt-5'>
                       <h1>Explore the Beauty and Adventure of Himachal Pradesh</h1>
                       <p>Himachal Pradesh, located in northern India, is a renowned tourist destination celebrated for its stunning landscapes, pleasant climate, and a variety of adventure activities. From serene retreats to thrilling expeditions, Himachal Pradesh offers a treasure trove of natural beauty that caters to every type of traveler.</p>
                        <h2>Geography of Himachal Pradesh</h2>
                        <p>Nestled in the Western Himalayas, Himachal Pradesh is known for its mountainous terrain. The region is home to prominent mountain ranges like the Pir Panjal, Dhauladhar, and parts of the Greater Himalayas. This diverse geography creates a dramatic landscape of snow-capped peaks, lush valleys, and rivers, making it a paradise for nature enthusiasts and adventure seekers alike.</p>
                        <h2>International Airports to Reach Himachal Pradesh</h2>
                        <ul>
                            <li><b>Indira Gandhi International Airport (Delhi):</b><br/>The primary international gateway to northern India, offering easy connections to Himachal Pradesh via road or connecting domestic flights.</li>
                            <li><b>Chandigarh International Airport:</b><br/>Located near Himachal Pradesh, it serves as a convenient hub for reaching destinations like Shimla, Manali, and Dharamshala.</li>
                            <li><b>Sri Guru Ram Dass Jee International Airport (Amritsar):</b><br/>An alternative gateway, especially useful for travelers coming from the Punjab region, with easy access to Himachal Pradesh via road.</li>
                        </ul>
                        <h2>Climate in Himachal Pradesh</h2>
                        <p>The climate in Himachal Pradesh varies with altitude:</p>
                        <ul>
                            <li><b>Summer (March to June):</b> Mild temperatures make it the best time for sightseeing and outdoor activities in places like Shimla, Manali, and Dharamshala.</li>
                            <li><b>Monsoon (July to September):</b> Heavy rainfall in the lower and mid-hills, adding a lush green cover to the valleys.</li>
                            <li><b>Winter (October to February):</b> Snowfall in higher altitudes like Manali and Spiti Valley, making it an ideal season for skiing and snow activities.</li>
                        </ul>
                        <h2>Cultural Insights of Himachal Pradesh</h2>
                        <ul>
                            <li><b>Languages:</b> Hindi is the official language, though Pahari, Punjabi, and Tibetan are also widely spoken.</li>
                            <li><b>Festivals:</b> Major festivals include Kullu Dussehra, Diwali, Holi, and local fairs like the Lavi Fair.</li>
                            <li><b>Art and Crafts:</b> Known for traditional handicrafts such as hand-woven carpets, woolens, and wood carvings, which are popular souvenirs.</li>
                        </ul>
                        <h2>Key Tourist Attractions in Himachal Pradesh</h2>
                        <h3>1.Shimla</h3>
                        <ul>
                            <li><b>Mall Road:</b> A bustling street featuring shops, cafes, and colonial architecture.</li>
                            <li><b>Jakhoo Temple:</b> Dedicated to Lord Hanuman, offering panoramic views.</li>
                            <li><b>Christ Church:</b> Renowned for its neo-Gothic architecture.</li>
                            <li><b>Kufri:</b> Popular for skiing and tobogganing in winter.</li>
                        </ul>
                        <h3>2.Manali</h3>
                        <ul>
                            <li><b>Solang Valley:</b> Famous for adventure sports like paragliding and zorbing.</li>
                            <li><b>Rohtang Pass:</b> A scenic mountain pass, offering snow activities.</li>
                            <li><b>Hadimba Temple:</b> An ancient temple surrounded by cedar forests.</li>
                        </ul>
                        <h3>3.Dharamshala and McLeod Ganj</h3>
                        <ul>
                            <li><b>Dalai Lama Temple Complex:</b> A major center for Tibetan Buddhism.</li>
                            <li><b>Bhagsu Waterfall:</b> A picturesque spot near McLeod Ganj.</li>
                            <li><b>Dharamkot:</b> A peaceful village ideal for hiking and panoramic views.</li>
                        </ul>
                        <h3>4.Kullu Valley</h3>
                        <ul>
                            <li><b>Great Himalayan National Park:</b> A UNESCO World Heritage Site, rich in biodiversity.</li>
                            <li><b>Kasol:</b> Known for its laid-back atmosphere and trekking routes.</li>
                        </ul>
                        <h3>5.Spiti Valley</h3>
                        <ul>
                            <li><b>Key Monastery:</b> A stunning Buddhist monastery.</li>
                            <li><b>Chandratal Lake:</b> A high-altitude lake surrounded by breathtaking landscapes.</li>
                        </ul> <h3>6.Lahaul Valley</h3>
                        <ul>
                            <li><b>Sissu:</b> A serene village with picturesque waterfalls.</li>
                            <li><b>Tandi:</b> Known for the confluence of the Chandra and Bhaga rivers.</li>
                        </ul> <h3>7.Palampur</h3>
                        <ul>
                            <li><b>Tea Gardens:</b> Explore tea estates and learn about tea cultivation.</li>
                            <li><b>Baijnath Temple:</b> An ancient temple dedicated to Lord Shiva.</li>
                            </ul> 
                            <h3>8.Dalhousie</h3>
                        <ul>
                            <li><b>Khajjiar:</b> Referred to as the “Mini Switzerland of India” for its lush meadows.</li>
                            <li><b>Dainkund Peak:</b> Offers panoramic views of valleys and mountain ranges.</li>
                        </ul>
                        <h3>9.Nahan</h3>
                        <ul>
                            <li><b>Renuka Lake:</b> A serene lake offering boating and scenic beauty.</li>
                        </ul>
                        <h3>Adventure Activities in Himachal Pradesh</h3>
                        <ul>
                            <li><b>Trekking:</b> Popular trails include Hampta Pass, Pin Parvati Pass, and the Triund Trek.</li>
                            <li><b>Camping:</b> Available at locations like Manali, Kasol, and Dharamshala.</li>
                            <li><b>River Rafting:</b> Exciting rafting experiences in Kullu and Tattapani.</li>
                            <li><b>Skiing:</b> Winter sports in Solang Valley and Kufri.</li>
                            <li><b>Paragliding:</b> Experience thrilling rides in Bir Billing and Solang Valley.</li>
                        </ul>
                    </div>
                </div>
            </div>
                <p className='himachal-destination-head mb-3 mt-5'>View Our Himachal Pradesh Packages</p>
                    <div className="destinations-container mb-5">
                    <div className="best-package-list">
                    {himachalPackages.map((pkg) => (
                        <div key={pkg.id} className="package-list-card mb-3">
                        <img
                            src={pkg.bannerImageUrl}
                            alt={pkg.destinationName}
                            className="list-card-img mb-3"
                        />
                        <div>
                            <h4 className="title-list mb-3">{pkg.destinationHead}</h4>
                            <h5 className="title-dates-list bold mb-3">{pkg.noOfDays}</h5>
                            <h5 className="title-rate-list bold mb-3">{pkg.cost}</h5>
                            <div className="btn-container-list-card">
                            <Link
                                to={`/package/leisure/${pkg.destinationName.replace(/\s+/g, "-")}`}
                                className="blogs-link"
                            >
                                <button className="view-button-list-card">
                                View Itinerary
                                </button>
                            </Link>
                            <Link to="/form" className="blogs-link">
                                <button className="view-button-list-card-book">
                                Book Now
                                </button>
                            </Link>
                            </div>
                        </div>
                        </div>
                    ))}
                    </div>
            </div>
            <Footer/>
            </HelmetProvider>
        </div>
    );
}

export default DestinationHimachal;
