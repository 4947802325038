import React from 'react';
import './index.css';

const Cancellation = () => {
    return (
        <div className='container mb-5'>
            <div className='row'>
                <div className='col-12 col-md-12 col-lg-12 col-xl-12'>
                    <h1 className='cancellation-head'>CANCELLATION & REFUND POLICIES</h1>
                    <h3 className='can-sub-head'>Cancellation & Refund Policy for Kenya tour packages</h3>
                    <p className='can-para'>Any non-refundable deposits paid to the hotels or airline tickets purchased, with prior notification to
                    the client or agent will not be refunded</p>
                    <p className='can-para'>Where no such deposits have been made, the below applies:</p>
                    <p className='can-para'>Cancellation prior to 60/90 days (depending with season) – only bank charges will be deducted</p>
                    <p className='can-para'>Cancellations received less than 60/90 days prior to departure will be subject to the following</p>
                    <p className='can-para can-bold'>CANCELLATION FEES</p>
                    <p className='can-para can-bold'>Low Season:</p>
                    <p className='can-para'>Less than 60 days 25%</p>
                    <p className='can-para'>Less than 45 days is 50%</p>
                    <p className='can-para'>Less than 15 days is 100%</p>
                    <p className='can-para'>Beyond 60 days – Full refund except bank charges (including service fees) and non-refundable
                    deposits (if any) at the hotels and air tickets</p>
                    <p className='can-para can-bold'>Peak Season:</p>
                    <p className='can-para'>Less than 90 days 25%</p>
                    <p className='can-para'>Less than 45 days is 50%</p>
                    <p className='can-para'>Less than 30 days is 100%</p>
                    <p className='can-para'>Beyond 90 days – Full refund except bank charges (including service fees) and non-refundable
                    deposits (if any) at the hotels and air tickets</p>
                    <h3 className='can-sub-head mt-5'>Cancellation & Refund Policy for Europe, Far East, Middle East & other
                    International tour packages</h3>
                    <p className='can-para'>All cancellations should be made in writing prior 1 month (30 days) to arrival date and should be
                    acknowledged by Travel Unbounded World Pvt Ltd, no cancellation fee will be charged and subject
                    to any group size.</p>
                    <p className='can-para'>For FIT reservation 06 passengers or less, cancellation will be charged as follows.</p>
                    <ul className='can-list'>
                        <li>21 days to 8 days prior to arrival date: 30% of total invoice charge</li>
                        <li>7 days to 4 days prior to arrival date: 50% of total invoice charge</li>
                        <li>3 days or less prior to arrival date: 100% of total invoice charge</li>
                    </ul>
                    <p className='can-para'>For GIT reservation 07 passengers or more, cancellation will be charged as follows.</p>
                    <ul className='can-list'>
                        <li>21 days to 15 days prior to arrival date: 50% of total invoice charges</li>
                        <li>14 days or less prior to arrival date: 100% of total invoice charges</li>
                    </ul>
                    <p className='can-para'>For any no show will be charged full total invoice charge.</p>
                    <p className='can-para'>Reduction in the number of passengers against requested bookings is considered and treated as
                    cancellations.</p>
                    <p className='can-para'>Postponement or changes of dates against requested bookings is considered and treated as
                    cancellations.</p>
                    <p className='can-para'>All deposits are non refundable. In the event of payment delay as per the deposit schedule, Travel
                    Unbounded World Pvt Ltd reserves the right to release confirmed services.</p>
                    <p className='can-para'>Travel Unbounded World Pvt Ltd reserves the right to release all services not guaranteed by deposit,
                    unless written credit arrangements subject to approval or fax undertaking of booking have been
                    received by Travel Unbounded World Pvt Ltd.</p>
                    <p className='can-para'>Both Travel Unbounded World Pvt Ltd and your side are deemed to have read, understood and
                    accepted the above.</p>
                    <h3 className='can-sub-head mt-5'>Cancellation & Refund Policy for India Wildlife tour packages</h3>
                    <p className='can-para'>Any non-refundable deposits paid to the hotels or airline tickets purchased, with prior notification to
                    the client or agent will not be refunded</p>
                    <p className='can-para'>Where no such deposits have been made, the below applies:</p>
                    <p className='can-para'>Cancellation prior to 60/90 days (depending with season) – only bank charges will be deducted</p>
                    <p className='can-para'>Cancellations received less than 60/90 days prior to departure will be subject to the following</p>
                    <p className='can-para can-bold'>CANCELLATION FEES</p>
                    <p className='can-para can-bold'>Low Season:</p>
                    <p className='can-para'>Less than 90 days 25%</p>
                    <p className='can-para'>Less than 60 days is 50%</p>
                    <p className='can-para'>Less than 45 days is 100%</p>
                    <p className='can-para'>Beyond 90 days – Full refund except bank charges (including service fees) and non-refundable
                    deposits (if any) at the hotels and air tickets</p>
                    <p className='can-para can-bold'>Peak Season:</p>
                    <p className='can-para'>Less than 120 days 25%</p>
                    <p className='can-para'>Less than 90 days is 50%</p>
                    <p className='can-para'>Less than 60 days is 100%</p>
                    <p className='can-para'>Beyond 120 days – Full refund except bank charges (including service fees) and non-refundable
                    deposits (if any) at the hotels and air tickets</p>
                    <h3 className='can-sub-head mt-5'>Cancellation & Refund Policy for India leisure / domestic tour packages</h3>
                    <p className='can-para'>Token amount will not be refundable.</p>
                    <p className='can-para'>25% cancellation charges will be applicable, if booking is cancelled between 45 to 30 days from the
                    date of travel.</p>
                    <p className='can-para'>50% cancellation charges will be applicable, if booking is cancelled between 30 to 15 days from the
                    date of travel.</p>
                    <p className='can-para'>100% cancellation charges will be applicable, if booking is cancelled within 15 days from the date
                    of travel.</p>
                    <p className='can-para'>Refund is not applicable in case of no show.</p>
                    <p className='can-para'>No refund for any trips booked during peak season and black out dates.</p>
                    <h3 className='can-sub-head text-center'>"All bank charges are to be borne by the customer when making the payment and in the event of cancellation."</h3>
                </div>
            </div>  
        </div>
    );
}

export default Cancellation;
