import React, {useState, useEffect} from 'react';
import Navbar from '../Navbar';
import Footer from '../Contact';
import HomeSlider from '../HomeSlider';
import {Link} from 'react-router-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import './index.css';

const images = [
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/bhutan%2Fb-6.webp?alt=media&token=2722abf8-7c7a-4ae3-9058-8453bfb4a7f6",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/bhutan%2Fb-9.webp?alt=media&token=4f2c1de3-dab6-491d-b618-c1641cfc73b5",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/bhutan%2Fb-8.webp?alt=media&token=8505e360-a5b8-47e6-b0f6-cd673a1d361c",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/bhutan%2Fb-4.webp?alt=media&token=193ae475-68ec-4578-bfc2-03d84a898378"
]
const DestinationBhutan = () => {
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        const fetchPackages = async () => {
          const db = firebase.firestore();
          try {
            const snapshot = await db.collection("PackageTemplate").get();
            const packageList = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setPackages(packageList);
          } catch (error) {
            console.error("Error fetching packages:", error);
          } 
        };
    
        fetchPackages();
      }, []);

      const keralaPackages = packages.filter(pkg => 
        pkg.destinationName.toLowerCase().includes('bhutan')
      );
    
    return (
        <>
         <HelmetProvider>
         <Helmet>
            <title>The Land of Thunder Dragon - Culture, Nature & Adventure | Travel Unbounded</title>
            <meta
                name="description"
                content="Discover the mystical beauty of Bhutan, a land of breathtaking landscapes, rich culture, and spiritual heritage. From the iconic Tiger’s Nest Monastery to lush valleys and serene temples, explore Bhutan's top destinations, travel tips, and adventure opportunities with Travel Unbounded. Plan your unforgettable Bhutan journey today!"
                />
            </Helmet>
            <Navbar/>
            <HomeSlider images={images}/>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-12 col-lg-12 col-xl-12 bhutan-container mt-5'>
                     <h1>Discover the Magic of Bhutan: A Comprehensive Guide to the Land of the Thunder Dragon</h1>
                     <p>Bhutan, a small Himalayan kingdom, is a mystical land where tradition and modernity coexist harmoniously. Known for its Gross National Happiness philosophy, Bhutan offers breathtaking landscapes, rich culture, and spiritual heritage. It is a haven for nature lovers, adventure seekers, and those looking for a peaceful retreat.</p>
                     <h2>Geography of Bhutan: A Land of Pristine Nature </h2>
                     <p>Bhutan's landscape is characterized by towering mountains, deep valleys, and dense forests. Nestled between India and China, Bhutan is home to dramatic Himalayan peaks, including the iconic Jomolhari, serene rivers, and lush green forests. Its untouched natural beauty makes Bhutan one of the most eco-friendly and serene destinations in the world.</p>
                     <h2>Airports in Bhutan: Limited Access, Maximum Experience  </h2>
                     <p>Bhutan has one international airport that connects it to the world. The kingdom limits the number of visitors to preserve its cultural and natural heritage, ensuring that each traveler experiences the authenticity of Bhutan.</p>
                     <ul>
                        <li><b>Paro International Airport (PBH):</b><br/>Situated in a valley surrounded by mountains, Paro International Airport is known for its challenging approach and scenic beauty. It is the only international gateway to Bhutan, offering flights from key cities like Delhi, Bangkok, Kathmandu, and Singapore.</li>
                     </ul>
                     <h2>Climate of Bhutan: A Diverse Range of Conditions  </h2>
                     <p>Bhutan experiences a wide range of climates due to its varying altitudes. The southern plains are subtropical, while the northern regions are alpine with year-round snow. Spring (March-May) and autumn (September-November) are the best times to visit, offering clear skies, pleasant weather, and a perfect backdrop for outdoor activities.</p>
                     <h2>Culture of Bhutan: A Unique Blend of Tradition and Spirituality  </h2>
                     <p>Bhutanese culture is deeply rooted in Buddhism, and the country is famous for its traditional festivals (Tsechus), colorful dress, and majestic dzongs (fortress monasteries). The government places a strong emphasis on preserving its traditions, and visitors can witness ancient rituals, dances, and ceremonies that are still very much a part of daily life.
                     </p>
                     <h2>Visa Information for Foreign Nationals</h2>
                     <p>All foreign visitors (except Indian nationals) must apply for a visa to enter Bhutan. Check <a href='https://immi.gov.bt/home/' target='__blank'>Visa Guidelines for Bhutan</a> Website for more information.</p>
                     <h2>Unique Bhutan Experiences: Must-Visit Destinations </h2>
                     <p>Bhutan offers a range of experiences, from spiritual retreats to thrilling treks. Here are the top destinations that showcase the beauty and serenity of this Himalayan kingdom.</p>
                     <h3>1. Spiritual Sites in Bhutan  </h3>
                     <ul>
                        <li><b>Taktsang Monastery (Tiger’s Nest):</b> Perched on a cliffside 3,000 meters above sea level, Tiger’s Nest Monastery is Bhutan’s most iconic landmark. The monastery is a spiritual haven and offers breathtaking views of the Paro Valley. The trek to Tiger’s Nest is challenging but rewarding, attracting pilgrims and trekkers alike.
                        </li>
                        <li><b>Punakha Dzong:</b> One of Bhutan’s most beautiful and historic dzongs, Punakha Dzong is situated at the confluence of the Pho Chhu and Mo Chhu rivers. This grand fortress-monastery serves as the winter residence of Bhutan’s spiritual leader and is a must-visit for its stunning architecture and serene surroundings.
                        </li>
                     </ul>
                     <h3>2. Adventure Activities in Bhutan  </h3>
                     <ul>
                        <li><b>Trekking the Jomolhari Trail:</b> For adventure seekers, the Jomolhari trek is one of Bhutan’s most popular trekking routes. The trail offers spectacular views of Mount Jomolhari, passing through pristine forests, high-altitude yak pastures, and remote villages. The trek also provides a glimpse of Bhutanese rural life.</li>
                        <li><b>White-Water Rafting in Punakha:</b> The rivers of Bhutan, particularly the Mo Chhu and Pho Chhu, offer exciting white-water rafting experiences. These rivers, fed by glaciers from the Himalayas, provide the perfect setting for thrilling rapids combined with views of Bhutan’s natural beauty.
                        </li>
                     </ul>
                     <h3>3. Bhutanese Festivals and Culture </h3>
                     <ul>
                        <li><b>Thimphu Tsechu Festival:</b> One of Bhutan’s largest and most vibrant festivals, the Thimphu Tsechu is held in the capital city every year. The festival features masked dances performed by monks, reenacting stories of Bhutanese legends. The celebrations provide a unique insight into Bhutan’s rich culture and religious traditions.</li>
                        <li><b>Paro Tsechu Festival:</b> Another major cultural festival in Bhutan, Paro Tsechu is a colorful celebration held at Paro Dzong. The highlight of the festival is the unveiling of the giant Thangka (religious scroll), which draws thousands of pilgrims from across the country.</li>
                     </ul>
                     <h3>4. Nature and Wildlife in Bhutan  </h3>
                     <ul>
                        <li><b>Phobjikha Valley:</b> The glacial valley of Phobjikha is a winter home to the rare and endangered black-necked cranes, which migrate from Tibet each year. This peaceful valley offers nature trails and traditional villages, providing a perfect escape for those looking to experience Bhutan’s rural charm.</li>
                        <li><b>Royal Manas National Park:</b> As one of Bhutan’s oldest national parks, Royal Manas is a biodiversity hotspot. The park is home to tigers, elephants, rhinos, and various bird species. It is ideal for eco-tourism and wildlife enthusiasts.</li>
                     </ul>
                     <h3>5. Bhutan’s Scenic Valleys </h3>
                     <ul>
                        <li><b>Paro Valley:</b> Paro is home to Bhutan’s only international airport and boasts scenic beauty and rich history. The valley is dotted with ancient temples, monasteries, and fortresses. Apart from Tiger’s Nest, you can visit Rinpung Dzong and explore Paro’s tranquil landscapes.</li>
                        <li><b>Bumthang Valley:</b> Known as the cultural heartland of Bhutan, Bumthang Valley is home to some of the kingdom’s most sacred monasteries and temples. The valley is also famous for its apple orchards, dairy products, and festivals like the Jambay Lhakhang Drup.
                        </li>
                     </ul>
                     <h3>6. Handicrafts and Local Markets in Bhutan  </h3>
                     <ul>
                        <li><b>Thimphu Craft Market:</b> The capital city of Thimphu offers a vibrant craft market where visitors can buy traditional Bhutanese textiles, handwoven scarves, wooden carvings, and other handicrafts. It’s the perfect place to find souvenirs while supporting local artisans.</li>
                        <li><b>Bumthang Yathra Weaving Centre:</b> In Bumthang, visit the Yathra Weaving Centre, where you can see local women weaving traditional textiles. Yathra is a unique Bhutanese textile, known for its bright colors and intricate patterns.</li>
                     </ul>
                     <h3>7. Wellness and Spiritual Retreats  </h3>
                     <ul>
                        <li><b>Amankora Wellness Retreats:</b> Bhutan is famous for its luxurious wellness retreats, and Amankora is one of the most sought-after experiences. Combining traditional Bhutanese therapies with modern spa treatments, these retreats offer a chance to rejuvenate in the midst of Bhutan’s tranquil landscapes.</li>
                        <li><b>Hot Stone Bath in Paro:</b> A traditional Bhutanese hot stone bath is a unique experience where water is heated by hot river stones and infused with medicinal herbs. It is believed to relieve joint pains and promote relaxation after a long day of trekking.
                        </li>
                     </ul>
                    </div>
                </div>
            </div>
                <p className='bhutan-destination-head mb-3 mt-5'>View Bhutan travel packages</p>
                    <div className="destinations-container mb-5">
                    <div className="best-package-list">
                    {keralaPackages.map((pkg) => (
                        <div key={pkg.id} className="package-list-card mb-3">
                        <img
                            src={pkg.bannerImageUrl}
                            alt={pkg.destinationName}
                            className="list-card-img mb-3"
                        />
                        <div>
                            <p className="title-list mb-3">{pkg.destinationHead}</p>
                            <p className="title-dates-list bold mb-3">{pkg.noOfDays}</p>
                            <p className="title-rate-list bold mb-3">{pkg.cost}</p>
                            <div className="btn-container-list-card">
                            <Link
                                to={`/package/leisure/${pkg.destinationName.replace(/\s+/g, "-")}`}
                                className="blogs-link"
                            >
                                <button className="view-button-list-card">
                                View Itinerary
                                </button>
                            </Link>
                            <Link to="/form" className="blogs-link">
                                <button className="view-button-list-card-book">
                                Book Now
                                </button>
                            </Link>
                            </div>
                        </div>
                        </div>
                    ))}
                    </div>
            </div>
            <Footer/>
            </HelmetProvider>
        </>
    );
}

export default DestinationBhutan;
