import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import axios from 'axios';
import './card.css';

const firebaseConfig = {
  apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
  authDomain: "phone-auth-d0a8d.firebaseapp.com",
  projectId: "phone-auth-d0a8d",
  storageBucket: "phone-auth-d0a8d.appspot.com",
  messagingSenderId: "309252587015",
  appId: "1:309252587015:web:3f9dc2505965e66269b4a2"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const CombinedPackageCard = () => {
  const [packages, setPackages] = useState([]);
  const [cards, setCards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPackages = async () => {
      const db = firebase.firestore();
      try {
        const snapshot = await db.collection("WildlifePackageTemplate").get();
        const packageList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPackages(packageList);
      } catch (error) {
        console.error("Error fetching packages:", error);
      }
    };

    const fetchCards = async () => {
      try {
        const response = await axios.get('https://formbackend.netlify.app/api/cards');
        setCards(response.data);
      } catch (error) {
        console.error('Error fetching card items:', error);
        setCards([]);
      }
    };

    const fetchData = async () => {
      setIsLoading(true);
      await fetchPackages();
      await fetchCards();
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const hotPackages = packages.filter(pkg =>
    pkg.destinationName?.toLowerCase().includes('kenya great migration')
  );

  if (isLoading) {
    return (
      <div className='loading-container'>
        <p className='loading'></p>
      </div>
    );
  }

  const combinedItems = [...hotPackages, ...cards];

  return (
    <div className="destinations-container">
      <div className="best-package-list">
        {combinedItems.map((item) => (
          <div key={item.id} className="package-list-card mb-3">
            <img
              src={item.bannerImageUrl || item.image}
              alt={item.destinationName || item.name}
              className="list-card-img mb-3"
            />
            <div>
              <p className="title-list mb-3">{item.destinationHead || item.name}</p>
              <p className="title-dates-list bold mb-3">{item.noOfDays || item.days}</p>
              <p className="title-rate-list bold mb-3">{item.cost || item.price}</p>
              <div className="btn-container-list-card">
                <Link
                  to={`/package/wildlife/${(item.destinationName || item.link).replace(/\s+/g, '-')}`}
                  className="blogs-link"
                >
                  <button className="view-button-list-card">View Itinerary</button>
                </Link>
                <Link to="/form" className="blogs-link">
                  <button className="view-button-list-card-book">Book Now</button>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CombinedPackageCard;
