import React from "react";
import "./terms.css";

const Terms = () => {
  return (
    <div>
      <h2 className="text-center mt-5 mb-5">Terms and Conditions</h2>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 col-xl-12">
            <p className="mb-3">
              The Website Owner, including subsidiaries and affiliates
              (“Website” or “Website Owner” or “we” or “us” or “our”) provides
              the information contained on the website or any of the pages
              comprising the website (“website”) to visitors (“visitors”)
              (cumulatively referred to as “you” or “your” hereinafter) subject
              to the terms and conditions set out in these website terms and
              conditions, the privacy policy and any other relevant terms and
              conditions, policies and notices which may be applicable to a
              specific section or module of the website.
            </p>
            <p className="mb-3">
              Welcome to our website. If you continue to browse and use this
              website you are agreeing to comply with and be bound by the
              following terms and conditions of use, which together with our
              privacy policy govern our relationship with you in relation to
              this website.
            </p>
            <p className="mb-3">
              The term &#39;TRAVELUNBOUNDED WORLD PRIVATE LIMITED&#39; or
              &#39;us&#39; or &#39;we&#39; refers to the owner of the website
              whose registered office is No 10, 8th Main, Vinayaka Nagar B
              block, Konena Agrahara, Bangalore 560017. All cases pertaining to
              our customers or vendors would be settled at Bangalore
              jurisdiction only.
            </p>
            <p className="mb-3">
              The term &#39;you&#39; refers to the user or viewer of our
              website.
            </p>
            <p className="mb-3">
              The use of this website is subject to the following terms of use:
            </p>
            <p className="mb-3">
              The content of the pages of this website is for your general
              information and use only. It is subject to change without notice.
            </p>
            <p className="mb-3">
              Neither we nor any third parties provide any warranty or guarantee
              as to the accuracy, timeliness, performance, completeness or
              suitability of the information and materials found or offered on
              this website for any particular purpose. You acknowledge that such
              information and materials may contain inaccuracies or errors and
              we expressly exclude liability for any such inaccuracies or errors
              to the fullest extent permitted by law.
            </p>
            <p className="mb-3">
              Your use of any information or materials on this website is
              entirely at your own risk, for which we shall not be liable. It
              shall be your own responsibility to ensure that any products,
              services or information available through this website meet your
              specific requirements.
            </p>
            <p className="mb-3">
              This website contains material which is owned by or licensed to
              us. This material includes, but is not limited to, the design,
              layout, look, appearance and graphics. Reproduction is prohibited
              other than in accordance with the copyright notice, which forms
              part of these terms and conditions.
            </p>
            <p className="mb-3">
              All trademarks reproduced in this website which are not the
              property of, or licensed to, the operator are acknowledged on the
              website.
            </p>
            <p className="mb-3">
              Unauthorized use of this website may give rise to a claim for
              damages and/or be a criminal offence.
            </p>
            <p className="mb-3">
              From time to time this website may also include links to other
              websites. These links are provided for your convenience to provide
              further information. They do not signify that we endorse the
              website(s). We have no responsibility for the content of the
              linked website(s).
            </p>
            <p className="mb-3">
              You may not create a link to this website from another website or
              document without prior written consent.
            </p>
            <p className="mb-3">
              Your use of this website and any dispute arising out of such use
              of the website is subject to the laws of India or other regulatory
              authority.
            </p>
            <p className="mb-3">
              &quot;We as a merchant shall be under no liability whatsoever in
              respect of any loss or damage arising directly or indirectly out
              of the decline of authorization for any Transaction, on Account of
              the Cardholder having exceeded the preset limit mutually agreed by
              us with our acquiring bank from time to time&quot;
            </p>

            <h4 className="mb-3 mt-5">Terms & Conditions</h4>
            <ul>
              <li className="mb-3">
                Airline seats and hotel rooms are subject to availability at the
                time of confirmation.
              </li>
              <li className="mb-3">
                In case of unavailability in the listed hotels, arrangement for
                an alternate accommodation will be made in a hotel of similar
                standard.
              </li>
              <li className="mb-3">
                There will be no refund for unused nights or early check-out (In
                case of Medical condition it completely depends on hotel
                policy).
              </li>
              <li className="mb-3">
                Check-in and check-out times at hotels would be as per hotel
                policies. Early check-in or late check-out is subject to
                availability and may be chargeable by the hotel.
              </li>
              <li className="mb-3">
                The price does not include expenses of personal nature, such as
                laundry, telephone calls, room service, alcoholic beverages,
                mini bar charges, tips, portage, camera fees etc.
              </li>
              <li className="mb-3">
                Travel Unbounded reserves the right to modify the itinerary at
                any point, due to reasons including but not limited to: Force
                Majeure events, strikes, fairs, festivals, weather conditions,
                traffic problems, overbooking of hotels / flights, cancellation
                / re-routing of flights, closure of / entry restrictions at a
                place of visit, etc. While we will do our best to make suitable
                alternate arrangements, we would not be held liable for any
                refunds/compensation claims arising out of this.
              </li>
              <li className="mb-3">
                In case a flight gets cancelled, Travel Unbounded will not be
                liable to provide any alternate flights within the same cost,
                any additional cost incurred for the same shall be borne by the
                traveller.
              </li>
              <li className="mb-3">
                If your stay is falling on 24th December, 31st December or 14th
                February, there may be mandatory Gala Dinner Charges additional
                that you need to pay at the hotel directly. This is applicable
                on all the black out days and would differ time to tome and
                Travel Unbounded is not liable for any such last minute changes
                or additions done.
              </li>
              <li className="mb-3">
                Cancellation policy would defer for every individual trip and
                would not be the same for the same. Please refer to the
                itinerary before you sign up.
              </li>
            </ul>
            <p className="mb-3">
              Kindly note : Unless specifically mentioned only non-ac rooms will
              be provided during all our tours.
            </p>
            <h4 className="mb-3 mt-5">Our Scope of Services</h4>
            <p className="mb-3">
              We are holiday organizers only. We inspect and select the services
              to be provided to you. However, we do not own, operate or control
              any airline, shipping company, coach or coach company, hotel,
              resort, transport, restaurant, kitchen caravan or any other
              facility or provider etc. that is engaged to provide you services
              during the course of your tour. Therefore, please carefully note
              that:
            </p>
            <p className="mb-3">
              you will need to adhere to the conditions, rules and regulations
              of each service provider. For instance, you will need to check the
              baggage rules of the airline, you will need to check the hotel /
              resort rules to check what the mealtimes are, at which you should
              make yourself available. The company is not responsible / liable
              for the consequences if you breach such rules and regulations.
            </p>
            <p className="mb-3">
              If you cause any injury or damage affecting the service provider,
              then you may be liable to the service provider and if the service
              provider recovers any monies from us for such injury or damages,
              we shall separately charge you for the same. This would be treated
              as an accepted rule when you sign for any of our tours.
            </p>
            <p className="mb-3">
              We cannot be held responsible / liable for any delay, deficiency,
              injury, death, loss or damage etc. occasioned due to act or
              default of such service providers, their employees or agents.
            </p>
            <h4 className="mb-3 mt-5">Payment Policies</h4>
            <ul>
              <li className="mb-3">
                Reservation or the amount mentioned in our itinerary under the
                Payment terms column has to be paid upfront for us to start
                confirming your package. Balance payment (if any) needs to be
                completed 45 days prior to travel. For travel in less than 45
                days from booking, complete payment must be received within 24
                hours of booking.
              </li>
              <li className="mb-3">
                You can pay using credit cards, debit cards, net banking,
                PayPal, NEFT, IMPS, RTGS, SWIFT or deposit cash in adhereance to
                Income Tax ACT. We levy non-refundable payment convenience fees
                for all payments made using our gateway. Bank transfers via
                NEFT/IMPS/RTGS/SWIFT do not attract convenience fees.
              </li>
              <li className="mb-3">
                We charge non refundable financing fees for overdue amounts:
                Financing charges: 3% per day of pending amount, compounding per
                day Processing charges for financing: 5% per week of pending
                amount or INR 10,000 per week, whichever is lower; from 2nd
                week.
              </li>
              <li className="mb-3">
                We reserve the right to cancel your trip without notice if the
                interest amount exceeds 10% of outstanding amount. Any
                cancellation costs will be borne by you.
              </li>
              <li className="mb-3">
                If the above mentioned payment terms are not followed Travel
                Unbounded will not be held responsible for any change made in
                the package itinerary due to non-availability of flights,
                Hotels, Resorts, Game drives, Park fees, Safaris, Core zones,
                Buffer zones,Ferry etc.
              </li>
              <li className="mb-3">
                There might be an increase in total package cost offered at the
                time of bookings in case the payments are not received by Travel
                Unbounded as per the terms mentioned and the extra cost need to
                be borne by the guest.
              </li>
            </ul>
            <p className="mb-3">
              Our Cancellation Policy for Kerala trips only and that too which
              are clearly specified : All applicable refunds would be only
              processed as a credit note which can be used by the guests who has
              booked for his or her next trip to the same destination. This is
              non transferable.
            </p>
            <ul>
              <li className="mb-3">
                Before 90 days to the departure : No charges
              </li>
              <li className="mb-3">
                From 90 days to 60 days prior to the departure date : 25% of
                Package Cost
              </li>
              <li className="mb-3">
                From 60 days to 45 days prior to the departure date : 50% of
                Package Cost
              </li>
              <li className="mb-3">
                From 45 days to 30 days prior to the departure date : 75% of
                Package Cost
              </li>
              <li className="mb-3">
                30 Days prior to departure date : Non Refundable
              </li>
            </ul>
            <p className="mb-3">
              Please Note: Cancellation policy is subject to change. It depends
              on the hotel policy.
            </p>
            <p className="mb-3">
              In peak season (example: long weekends, festival season, summer
              vacation etc.) we would charge 100% of the tour cost as the
              cancellation fee and no efund would be provided during these
              season/days.
            </p>
            <h4 className="mb-3 mt-5">Amendment of Booking by Guest</h4>
            <p className="mb-5">
              If you wish to amend or change your booking, you have to
              communicate your request to us in writing to 
              support@travelunbounded.com . Such requests for change or
              amendment will be accepted subject to availability. Please note
              that the amended or changed booking will be regarded as a new
              booking. In case the amendment is carried out within the
              cancellation period, then a cancellation charge shall apply as if
              a cancellation was made on the date the request for amendment or
              change is made. Please note the cancellation charges will be as
              per the airline and hotel policies. Travel Unbounded will not levy
              any separate cancellation charges for amendment purposes.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Terms;
