import React, {useState, useEffect} from 'react';
import Navbar from '../Navbar';
import Footer from '../Contact';
import HomeSlider from '../HomeSlider';
import {Link} from 'react-router-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import './index.css';

const images = [
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/WildlifePackageTemplate%2FParwali%20Jr.jpg?alt=media&token=17e9d5cc-5613-4cc0-9b50-6083a928e8ee",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/WildlifePackageTemplate%2Fpexels-chiru-clicks-1518097-2926038.jpg?alt=media&token=3f8fd779-02f8-4f72-bc5d-7eafaf7feb74",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Home%2FTuskers%20from%20kenya-Wildlife%20safari%20(1%20of%201).webp?alt=media&token=44128795-9c35-4c1f-baa5-5eb9b4f53709",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TUCorbett%2FPic%205.jpg?alt=media&token=4ea19022-0aba-4163-b5e2-9fb9051a5e85"
]

const DestinationCorbett = () => {
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        const fetchPackages = async () => {
          const db = firebase.firestore();
          try {
            const snapshot = await db.collection("WildlifePackageTemplate").get();
            const packageList = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setPackages(packageList);
          } catch (error) {
            console.error("Error fetching packages:", error);
          } 
        };
    
        fetchPackages();
      }, []);

      const keralaPackages = packages.filter(pkg => 
        pkg.destinationName.toLowerCase().includes('corbett')
      );
    
    return (
        <>
         <HelmetProvider>
         <Helmet>
            <title>Discover Corbett - Tiger Reserve, Safaris & Tours | Travel Unbounded</title>
            <meta
                name="description"
                content="Explore Corbett National Park’s wildlife and zones with Travel Unbounded. Book safaris in Dhikala, Bijrani, and Jhirna for an unforgettable adventure. Visit Corbett Museum and experience the legacy of Jim Corbett."
                />
            </Helmet>
            <Navbar/>
            <HomeSlider images={images}/>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-12 col-lg-12 col-xl-12 corbett-container mt-5'>
                      <h1>Corbett – The Land of the Roar and Trumpet</h1>
                      <p>Nestled in the foothills of the majestic Himalayas, Corbett is a serene wilderness destination, away from the crowds and full of natural beauty. The Ramganga River winds gracefully through the valley, dividing lush, dense forests from sweeping grasslands. Corbett offers an escape into nature with landscapes that captivate and inspire.</p>
                      <h2>Wildlife & Birdwatching in Corbett</h2>
                      <p>Corbett National Park is home to an extraordinary diversity of wildlife, including:</p>
                      <ul>
                        <li><b>Over 50 mammal species</b></li>
                        <li><b>Nearly 400 species of birds</b></li>
                        <li><b>Three major zones,</b> each with unique ecosystems and biodiversity</li>
                      </ul>
                      <p>The Dhikala Zone, the most popular, features vast grasslands, water bodies, and dense Sal forests. To explore this area fully, visitors often stay within the forest rest house inside the zone for a truly immersive experience.</p>
                      <p>For guests exploring other zones, resorts in Ramnagar offer convenient accommodations, or you can stay at forest rest houses within each area. Bijrani and Jhirna are the other well-known zones:</p>
                      <ul>
                        <li><b>Bijrani Zone:</b> Known for its diverse terrain of riverbeds, thick shrubs, and rocky hills, Bijrani has a rich concentration of wildlife and bird species.</li>
                        <li><b>Jhirna Zone:</b> Known for its dense elephant population and remarkable birdlife, Jhirna offers a thrilling experience where tiger sightings, though rare, are unforgettable.</li>
                      </ul>
                      <h2>How to Get to Corbett</h2>
                      <p>Corbett is easily accessible from various cities. Visitors should plan to arrive at Ramnagar to start their journey into the park’s zones. Travel options include:</p>
                      <ul>
                        <li><b>By Train:</b> Ramnagar has a direct rail connection with major cities.</li>
                        <li><b>By Road:</b> Well-connected by road from Delhi and other North Indian cities.</li>
                      </ul>
                      <p>Make sure to check visa requirements if you're an international visitor.</p>
                      <h2>Visa Requirements</h2>
                      <p>Most foreign nationals require a visa to enter India. Depending on the traveler’s nationality, stay duration, and purpose, various visa types are available. Many can apply for an e-Visa online, which is processed swiftly and delivered via email. <a href='https://indianvisaonline.gov.in/evisa' target='__blank'>Click here to apply for an e-Visa</a>. Nationals of certain countries must obtain a visa directly from an Indian embassy.</p>
                      <h2>Must-See Attractions in Corbett</h2>
                      <h3>1. Corbett Tiger Reserve (CTR)</h3>
                      <p>The Corbett Tiger Reserve (CTR) is divided into multiple zones, each offering unique landscapes and safari experiences, from lush grasslands to rocky terrains and meandering rivers.</p>
                      <ul>
                        <li><b>Dhikala Zone:</b> Featuring a guest house within the core area, Dhikala allows visitors to immerse in Indian wildlife.</li>
                        <li><b>Bijrani and Jhirna Zones:</b> These zones are accessible from Ramnagar and offer exciting jungle drives. Safaris can be booked through your resort or tour operator for easy access and local guidance.</li>
                      </ul>
                      <h3>2. Corbett Museum</h3>
                      <p>A short 35-minute drive from Ramnagar, the Corbett Museum in Kaladhungi was once home to the legendary Jim Corbett. This well-preserved museum showcases his life, contributions, and legacy. Visiting this place offers a profound look into Corbett’s impact on conservation and the local community.</p>
                      <h3>3. Ramnagar Market</h3>
                      <p>While staying in Ramnagar, be sure to explore the bustling Ramnagar Market. Full of local activity, this market is the perfect place to shop for everything from fresh produce and provisions to clothes and souvenirs. Enjoy a lively, authentic shopping experience, perfect for an evening stroll before returning to your resort.</p>
                      <h3>Safari and Accommodation Options in Corbett</h3>
                      <p>To get the full Corbett experience, safari bookings are essential. Many resorts offer booking services, or you can arrange safaris through tour operators. Accommodation options include:</p>
                      <ul>
                      <li><b>Forest Rest Houses</b> within the park, especially in Dhikala for those seeking immersive wildlife stays.</li>
                      <li><b>Resorts in Ramnagar,</b> offering easy access to Bijrani, Jhirna, and other zones.</li>
                      </ul>
                      <h2>Plan Your Corbett Adventure with Travel Unbounded</h2>
                      <p>Let us help you plan the perfect Corbett experience! Contact us for personalized itineraries, safari arrangements, and accommodation options that fit your travel style and preferences.</p>
                     </div>
                </div>
            </div>
                <p className='corbett-destination-head mb-3 mt-5'>View Our Corbett Packages</p>
                    <div className="destinations-container mb-5">
                    <div className="best-package-list">
                    {keralaPackages.map((pkg) => (
                        <div key={pkg.id} className="package-list-card mb-3">
                        <img
                            src={pkg.bannerImageUrl}
                            alt={pkg.destinationName}
                            className="list-card-img mb-3"
                        />
                        <div>
                            <p className="title-list mb-3">{pkg.destinationHead}</p>
                            <p className="title-dates-list bold mb-3">{pkg.noOfDays}</p>
                            <p className="title-rate-list bold mb-3">{pkg.cost}</p>
                            <div className="btn-container-list-card">
                            <Link
                                to={`/package/wildlife/${pkg.destinationName.replace(/\s+/g, "-")}`}
                                className="blogs-link"
                            >
                                <button className="view-button-list-card">
                                View Itinerary
                                </button>
                            </Link>
                            <Link to="/form" className="blogs-link">
                                <button className="view-button-list-card-book">
                                Book Now
                                </button>
                            </Link>
                            </div>
                        </div>
                        </div>
                    ))}
                    </div>
            </div>
            <Footer/>
            </HelmetProvider>
        </>
    );
}

export default DestinationCorbett;
