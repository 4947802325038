import React, { useEffect, useState } from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import './index.css'; 
import { MdDeleteForever } from "react-icons/md";

const QuizResponsesComponent = () => {
  const [responses, setResponses] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [responseToDelete, setResponseToDelete] = useState(null);
  const [loading, setLoading] = useState(true); // To handle loading state

  useEffect(() => {
    const fetchResponses = async () => {
      try {
        const responsesSnapshot = await firebase.firestore().collection('quizResponses').get();
        const responsesData = responsesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setResponses(responsesData);
      } catch (error) {
        console.error("Error fetching responses: ", error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchResponses();
  }, []);

  const openDeleteModal = (id) => {
    setResponseToDelete(id);
    setIsModalOpen(true);
  };

  const closeDeleteModal = () => {
    setResponseToDelete(null);
    setIsModalOpen(false);
  };

  const confirmDelete = async () => {
    if (responseToDelete) {
      try {
        await firebase.firestore().collection('quizResponses').doc(responseToDelete).delete();
        setResponses((prevResponses) => prevResponses.filter(response => response.id !== responseToDelete));
        closeDeleteModal();
      } catch (error) {
        console.error("Error deleting document: ", error);
      }
    }
  };

  return (
    <div className="responses-container">
      {loading ? (
        <p>Loading responses...</p>
      ) : (
        <>
          {responses.length === 0 ? (
            <p className='no-response'>No responses yet!</p> 
          ) : (
            responses.map((response) => (
              <div className="response-card" key={response.id}>
                <div className="response-card-header">
                  <h3 className="response-title">Quiz Response</h3>
                  <button className="delete-button" onClick={() => openDeleteModal(response.id)}>
                    <MdDeleteForever size={20} />
                  </button>
                </div>

                <div className="response-details">
                  <p><strong>Name:</strong> {response.name || "N/A"}</p>
                  <p><strong>Email:</strong> {response.email || "N/A"}</p>
                  <p><strong>Address:</strong> {response.address || "N/A"}</p>
                  <p><strong>Answer 1:</strong> {response.q1 || "N/A"}</p>
                  <p><strong>Answer 2:</strong> {response.q2 || "N/A"}</p>
                </div>
              </div>
            ))
          )}
        </>
      )}

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <p>Are you sure you want to delete this document?</p>
            <div className="modal-buttons">
              <button className="modal-button confirm" onClick={confirmDelete}>Yes</button>
              <button className="modal-button cancel" onClick={closeDeleteModal}>No</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuizResponsesComponent;
