import React, {useState, useEffect} from 'react';
import Navbar from '../Navbar';
import Footer from '../Contact';
import HomeSlider from '../HomeSlider';
import {Link} from 'react-router-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import './index.css';

const images = [
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/bandipur%2Fbandipur-2.webp?alt=media&token=6c730b0a-45f0-42a9-af93-8eb7ce5b5f66",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/bandipur%2Fbandipur-3.webp?alt=media&token=4fae72e9-5f14-45e5-8b91-a859041f4971",
]
const DestinationBandipur = () => {
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        const fetchPackages = async () => {
          const db = firebase.firestore();
          try {
            const snapshot = await db.collection("WildlifePackageTemplate").get();
            const packageList = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setPackages(packageList);
          } catch (error) {
            console.error("Error fetching packages:", error);
          } 
        };
    
        fetchPackages();
      }, []);

      const keralaPackages = packages.filter(pkg => 
        pkg.destinationName.toLowerCase().includes('bandipur')
      );
    
    return (
        <>
         <HelmetProvider>
         <Helmet>
            <title>Discover Bandipur National Park: Where Nature's Symphony Plays</title>
            <meta
                name="description"
                content="Embark on a wild adventure in Bandipur National Park. Witness diverse flora and fauna, thrilling safaris, and the enchanting beauty of Southern India's wildlife haven."
            />
            </Helmet>
            <Navbar/>
            <HomeSlider images={images}/>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-12 col-lg-12 col-xl-12 bandipur-container mt-5'>
                      <h1>Bandipur: A Premier Wildlife Safari Destination in India</h1>
                      <p>Bandipur, nestled in the heart of Karnataka, is one of India's most celebrated wildlife safari destinations. As part of the Nilgiri Biosphere Reserve, this stunning area offers rich biodiversity, making it an essential stop for nature lovers and wildlife enthusiasts.</p>
                      <h2>Discover Bandipur National Park</h2>
                      <h3>Overview</h3>
                      <p>Bandipur National Park, once the hunting ground of the Mysore Maharajas, is now a protected area that showcases the diverse flora and fauna of the region. Spanning over 874 square kilometers, it is renowned for its significant population of elephants, tigers, and numerous other wildlife species.</p>
                      <h3>Highlights</h3>
                      <ul>
                        <li><b>Diverse Wildlife:</b> Bandipur is home to the Big 5 of India: tigers, leopards, elephants, gaurs, and Indian bison.</li>
                        <li><b>Birdwatcher's Paradise:</b> The park boasts over 200 bird species, making it a haven for ornithologists and bird enthusiasts.</li>
                        <li><b>Scenic Landscapes:</b> Experience breathtaking views of the Western Ghats and lush green forests that provide a stunning backdrop for wildlife safaris.</li>
                      </ul>
                      <h2>Best Times to Visit</h2>
                      <p>The ideal time to visit Bandipur National Park is between October and March when the weather is pleasant and wildlife sightings are more frequent. The dry months enhance visibility as animals congregate around water sources.</p>
                      <h2>Safari Experiences</h2>
                      <h3>Jeep Safaris</h3>
                      <p>Experience the thrill of a guided jeep safari through Bandipur’s stunning landscapes. Knowledgeable guides ensure that you witness the majestic wildlife in their natural habitat while providing insights into the ecosystem.</p>
                      <h3>Nature Walks</h3>
                      <p>For a more immersive experience, opt for a nature walk led by expert naturalists. Explore the intricate details of the flora and fauna and learn about conservation efforts in the region.</p>
                      <h2>Nearby Attractions</h2>
                      <h3>1. Mudumalai National Park</h3>
                      <p>Just a short drive from Bandipur, Mudumalai National Park is known for its diverse wildlife and beautiful landscapes. Visitors can enjoy safaris and the opportunity to spot rare species such as the Nilgiri Tahr.</p>
                      <h3>2. Ooty</h3>
                      <p>Famous for its cool climate and stunning views, Ooty is a popular hill station located close to Bandipur. Visitors can explore botanical gardens, scenic lakes, and tea plantations.</p>
                      <h3>3. Wayanad</h3>
                      <p>Explore the lush greenery of Wayanad, known for its spice plantations, waterfalls, and wildlife sanctuaries. It’s an excellent destination for trekking and eco-tourism.</p>
                      <h2>Accommodation Options</h2>
                      <p>Bandipur offers a variety of accommodations, ranging from luxury resorts to budget lodges. Popular options include:</p>
                      <ul>
                        <li><b>The Serai Bandipur:</b> A luxury resort offering comfortable stays with stunning views and wildlife experiences.</li>
                        <li><b>Bandipur Safari Lodge:</b> Known for its eco-friendly approach and proximity to the park.</li>
                      </ul>
                      <h2>Travel Tips</h2>
                      <ul>
                        <li><b>Entry Fee:</b> There is an entry fee to access Bandipur National Park; ensure you have the correct amount ready.</li>
                        <li><b>Guided Tours:</b> Opt for guided tours for the best wildlife experiences and to ensure safety during your visit.</li>
                        <li><b>Respect Wildlife:</b> Maintain a safe distance from all wildlife and adhere to park rules to ensure a safe and enjoyable experience.</li>
                      </ul>
                      <h2>Getting There</h2>
                      <ul>
                        <li><b>By Road:</b> Bandipur is well-connected by road, making it easily accessible from major cities like Mysore and Bangalore.</li>
                        <li><b>By Train:</b> The nearest railway station is Mysore, approximately 80 kilometers away.</li>
                        <li><b>By Air:</b> The nearest airport is in Coimbatore, about 160 kilometers from Bandipur.</li>
                      </ul>
                    </div>
                </div>
            </div>
                <p className='bhutan-destination-head mb-3 mt-5'>View Our Bandipur packages</p>
                    <div className="destinations-container mb-5">
                    <div className="best-package-list">
                    {keralaPackages.map((pkg) => (
                        <div key={pkg.id} className="package-list-card mb-3">
                        <img
                            src={pkg.bannerImageUrl}
                            alt={pkg.destinationName}
                            className="list-card-img mb-3"
                        />
                        <div>
                            <p className="title-list mb-3">{pkg.destinationHead}</p>
                            <p className="title-dates-list bold mb-3">{pkg.noOfDays}</p>
                            <p className="title-rate-list bold mb-3">{pkg.cost}</p>
                            <div className="btn-container-list-card">
                            <Link
                                to={`/package/wildlife/${pkg.destinationName.replace(/\s+/g, "-")}`}
                                className="blogs-link"
                            >
                                <button className="view-button-list-card">
                                View Itinerary
                                </button>
                            </Link>
                            <Link to="/form" className="blogs-link">
                                <button className="view-button-list-card-book">
                                Book Now
                                </button>
                            </Link>
                            </div>
                        </div>
                        </div>
                    ))}
                    </div>
            </div>
            <Footer/>
            </HelmetProvider>
        </>
    );
}

export default DestinationBandipur;
