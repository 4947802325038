import React, { useState, useEffect } from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { FiX, FiEye, FiEyeOff } from "react-icons/fi";
import { toast, Toaster } from "react-hot-toast";
import './index.css';
import Dashboard from '../AdminDashboard';

const firebaseConfig = {
  apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
  authDomain: "phone-auth-d0a8d.firebaseapp.com",
  projectId: "phone-auth-d0a8d",
  storageBucket: "phone-auth-d0a8d.appspot.com",
  messagingSenderId: "309252587015",
  appId: "1:309252587015:web:3f9dc2505965e66269b4a2"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loggedInUser, setLoggedInUser] = useState(null);

    useEffect(() => {
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                setLoggedInUser(user);
            } else {
                setLoggedInUser(null);
            }
        });
    }, []);

    const handleEmailChange = (e) => setEmail(e.target.value);
    const handlePasswordChange = (e) => setPassword(e.target.value);

    const togglePasswordVisibility = () => setShowPassword(!showPassword);

    const handleLogin = async () => {
        try {
            await firebase.auth().signInWithEmailAndPassword(email, password);
            console.log('Logged in successfully!');
            setLoggedInUser(true);
            setEmail('');
            setPassword('');
            toast.success('Logged in successfully');
        } catch (error) {
            console.error('Error signing in:', error);
            toast.error('Please enter valid Email or Password');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    const handleGoback = () => {
        window.history.back();
    };

    return (
        <>
        <div className='parent-container'>
                <Toaster toastOptions={{ duration: 5000 }} style={{ fontStyle: 'Canva Sans', color: 'black' }} />
                {loggedInUser ? (
                    <Dashboard/>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <div className='signin-container'>
                            <div className='element-container'>
                                <div
                                    onClick={handleGoback}
                                    style={{ position: "absolute", top: "10px", right: "10px", cursor: 'pointer' }}
                                >
                                    <FiX size={30} />
                                </div>
                                <h3 className='login-head mb-3 text-center'>Login</h3>
                                <label>Email</label>
                                <input type="email" className='mb-3' placeholder="Enter email" value={email} onChange={handleEmailChange} autoComplete='username' required />
                                <label>Password</label>
                                <div className="password-input-container">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        className='mb-3 password-input'
                                        placeholder="Enter password"
                                        value={password}
                                        onChange={handlePasswordChange}
                                        autoComplete="current-password"
                                        required
                                    />
                                    <div className="eye-icon-container" onClick={togglePasswordVisibility}>
                                        {showPassword ? <FiEye size={20} /> : <FiEyeOff size={20} />}
                                    </div>
                                </div>
                                <button onClick={handleLogin} type='submit' className='btn-submit-mail'>Login with Email</button>
                            </div>
                        </div>
                    </form>
                )}
            </div>
        </>
    );
};

export default Login;
