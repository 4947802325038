import React, { useState } from 'react';
import './index.css';
import { FiX } from "react-icons/fi";
import OtpInput from "otp-input-react";
import PhoneInput from "react-phone-number-input";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { toast, Toaster } from "react-hot-toast";
import { HelmetProvider, Helmet } from 'react-helmet-async';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const BookingForm = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [confirmationResult, setConfirmationResult] = useState(null);

  const handleSendOTP = async (e) => {
    e.preventDefault();
    try {
      const appVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container"
      );
      const confirmation = await firebase
        .auth()
        .signInWithPhoneNumber(`+${phoneNumber}`, 
        appVerifier);
      setConfirmationResult(confirmation);
      toast.success("OTP sent successfully");
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    try {
      await confirmationResult.confirm(otp);
      toast.success("OTP Verified Successfully");
      setOtp("");
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("Please Enter Correct OTP");
    }
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    country: "",
    destination: "",
    date: "",
    noOfTravellers: "",
    duration: "",
    callbackTime: "",
    d2h: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const {
        name,
        email,
        country,
        destination,
        date,
        noOfTravellers,
        duration,
        callbackTime,
        d2h,
      } = formData;
      const db = firebase.firestore();
      db.collection("formData").add({
        name: name,
        email: email,
        phoneNumber:phoneNumber,
        country: country,
        destination: destination,
        date: date,
        noOfTravellers: noOfTravellers,
        duration: duration,
        callbackTime: callbackTime,
        d2h: d2h,
      });
      const response = await fetch("https://formbackend.netlify.app/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          country,
          destination,
          date,
          noOfTravellers,
          duration,
          callbackTime,
          phoneNumber,
          d2h,
        }),
      });
      const data = await response.json();
      console.log(data.message);
      toast.success("Booking Successfull");
      setFormData({
        name: "",
        email: "",
        country: "",
        destination: "",
        date: "",
        noOfTravellers: "",
        duration: "",
        callbackTime: "",
        d2h:"",
      });
      setPhoneNumber("");
      if (window.gtag) {
        window.gtag('event', 'conversion', {
          'send_to': 'AW-870473623/KRgLCN7oh9QZEJe_iZ8D',
        });
      } else {
        console.error("Google Tag not initialized");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const minDate = new Date();
  minDate.setDate(minDate.getDate() + 30);

  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <>
    <HelmetProvider>
    <Helmet>
        <title>Book your journey now with Travel Unbounded.</title>
        <meta
          name="description"
          content="Book now for an unforgettable journey into the heart of the wild. Don't miss out on thrilling safaris and breathtaking encounters"
        />
        <script type="text/javascript">
          {`
            function gtag_report_conversion(url) {
              var callback = function () {
                if (typeof(url) != 'undefined') {
                  window.location = url;
                }
              };
              gtag('event', 'conversion', {
                  'send_to': 'AW-870473623/JHjsCNys39kZEJe_iZ8D',
                  'event_callback': callback
              });
              return false;
            }
          `}
        </script>
       
      </Helmet>
      <Toaster toastOptions={{ duration: 5000 }} />
    <div className="section" id="booking">
      <div className="section-center">
        <div className="container">
          <div className="row">
            <div className="booking-form-new">
            <div onClick={handleGoBack} className="close-button-new-form">
              <FiX size={20} />
            </div>
              <h1 className='booking-form-new-head mb-3'>Book Your Dream Holiday Today!</h1>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <span className="form-label">Name*</span>
                      <input className="form-control" 
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      type="text" 
                      placeholder="Enter your name" required/>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <span className="form-label">Email*</span>
                      <input className="form-control" 
                      type="email" 
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Enter your email" required/>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <span className="form-label">Country of Residence*</span>
                      <input className="form-control"
                      name="country"
                      value={formData.country}
                      onChange={handleChange}
                       type="text" 
                       placeholder="Enter your country" required/>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <span className="form-label">Select Destination*</span>
                      <select className="form-control"
                        name="destination"
                        value={formData.destination}
                        onChange={handleChange}  required>
                        <option value='' disabled
                        >Choose destination</option>
                        <option value="BAN">Bandipur</option>
                        <option value="BNDVG">Bandhavgarh</option>
                        <option value="CRT">Corbett</option>
                        <option value="KAB">Kabini</option>
                        <option value="KER">Kerala</option>
                        <option value="HIM">Himachal Pradesh</option>
                        <option value="SPR">Satpura</option>
                        <option value="HIM">Bali</option>
                        <option value="KEN">Kenya</option>
                        <option value="PEN">Pench</option>
                        <option value="RTR">Ranthambore</option>
                        <option value="TAN">Tanzania</option>
                        <option value="VTNM">Vietnam</option>
                        <option value="CMBD">Combodia</option>
                        <option value="BHU">Bhutan</option>
                        <option value="NPL">Nepal</option>
                        <option value="ICLND">Iceland</option>
                        <option value="SA">South Africa</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <span className="form-label">No. of Travelers*</span>
                      <input className="form-control"
                      name="noOfTravellers"
                      value={formData.noOfTravellers}
                      onChange={handleChange} type="number" placeholder="Enter number of travelers" required/>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <span className="form-label">Duration of Stay*</span>
                      <input className="form-control"
                      name="duration"
                      value={formData.duration}
                      onChange={handleChange} type="text" placeholder="Enter days of stay" required/>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <span className="form-label">Arrival Date*</span>
                      <input className="form-control"
                      id='date'
                       min={minDate.toISOString().split("T")[0]}
                      name="date"
                      value={formData.date}
                      onChange={handleChange} type="date" required/>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <span className="form-label">Flexible Time to Reach Out</span>
                      <input className="form-control"
                      name="callbackTime"
                      value={formData.callbackTime}
                      onChange={handleChange} type="text" placeholder="Enter preferred contact time" />
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <span className="form-label">Message</span>
                      <input className="form-control"
                       name="d2h"
                       value={formData.d2h}
                       onChange={handleChange} type="text" placeholder="Message" />
                    </div>
                  </div>

                  <div className="col-12 form-group-inline">
                    <div className="form-group">
                    <span className="form-label">Enter Phone Number*</span>
                      <PhoneInput
                        defaultCountry="IN"
                        value={phoneNumber}
                        placeholder="Enter Contact Number"
                        onChange={(value) => setPhoneNumber(value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                    <span className="form-label"></span>
                      <button
                        type="button"
                        onClick={handleSendOTP}
                        className="phone-btn"
                      >
                        Send OTP
                      </button>
                    </div>
                    <div className="form-group-captcha">
                    <div id="recaptcha-container"></div>
                    </div>
                    <div className="form-group">
                    <span className="form-label">Enter OTP</span>
                    <OtpInput
                    value={otp}
                    onChange={setOtp}
                    OTPLength={6}
                    otpType="number"
                    disabled={false}
                    required
                  ></OtpInput>
                    </div>
                    <div className="form-group">
                    <span className="form-label"></span>
                      <button
                        type="button"
                        onClick={handleVerifyOTP}
                        className="phone-btn"
                      >
                        Verify OTP
                      </button>
                    </div>
                  </div>
                  <div className="form-btn-book-now">
                    <button className="submit-btn" type="submit">Book Now</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    </HelmetProvider>
    </>
  );
};

export default BookingForm;
