import React, {useState, useEffect} from 'react';
import './index.css';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { toast, Toaster } from "react-hot-toast";
import axios from 'axios';
import { useParams } from 'react-router-dom';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const PayNowForm = () => {
  const {destinationName} = useParams();
  const formattedDestination = destinationName.replace(/-/g, ' ');
  const [packageData, setPackageData] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    adults: '',
    children: '',
    arrivalDate: '',
  });

  useEffect(() => {
    const fetchPackage = async () => {
      const db = firebase.firestore();
      try {
        const docRef = db.collection('PackageTemplate').doc(formattedDestination);
        const doc = await docRef.get();
        if (doc.exists) {
          setPackageData(doc.data());
        } else {
          console.warn("No package found");
        }
      } catch (error) {
        console.error("Error fetching package:", error);
      }
    };

    fetchPackage();
  }, [formattedDestination]);

  if (!packageData) {
    return <p>No package details available.</p>;
  }

  const { price = 0 } = packageData;

  const minDate = new Date();
  minDate.setDate(minDate.getDate() + 30);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePayment = async () => {
    try {
      const response = await axios.post('https://formbackend.netlify.app/payment', {
        amount: price * 100,
      });
      const { data } = response;

      const options = {
        key: 'rzp_test_2U3wItuISJsrjG',
        amount: data.amount,
        currency: 'INR',
        name: 'Travel Unbounded',
        description: 'Test Transaction',
        order_id: data.id,
        handler: async function (response) {
          const paymentId = response.razorpay_payment_id;
          try {
            const bookingId = generateBookingId();
            const bookingRef = await firebase.firestore().collection('paymentData').add({
              name: formData.name,
              email: formData.email,
              phone: formData.phone,
              adults: formData.adults,
              children: formData.children,
              arrivalDate: formData.arrivalDate,
              bookingId: bookingId,
              paymentId: paymentId,
              totalPrice: price,
              timestamp: new Date(),
            });
            console.log('Booking added with ID:', bookingRef.id);
            toast.success('Booking successful!');
            setFormData({
              name: '',
              email: '',
              phone: '',
              adults: '',
              children: '',
              arrivalDate: '',
            });
          } catch (error) {
            console.error('Error adding booking:', error);
            toast.error('Error adding booking. Please try again later.');
          }
        },
        prefill: {
          name: formData.name,
          email: formData.email,
          contact: formData.phone,
        },
        theme: {
          color: 'rgb(137, 66, 179)',
        },
      };
      if (window.Razorpay) {
        const rzp = new window.Razorpay(options);
        rzp.open();
      } else {
        toast.error('Razorpay is not loaded. Please try again later.');
      }
    } catch (error) {
      console.error('Error while processing payment:', error);
      toast.error('Payment failed. Please try again.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handlePayment();
  };

  const generateBookingId = () => {
    return 'BOOK' + Math.floor(Math.random() * 1000000);
  };

  return (
    <div className="form-container-pay">
      <Toaster 
        toastOptions={{ duration: 5000 }} 
        position="top-center"
        containerStyle={{ top: '100px' }}
      />
      <form className="responsive-form-pay" onSubmit={handleSubmit}>
      <h1 className='text-center mb-3'>Pay Now</h1>
        <div className="form-row-pay">
          <input type="text" name="name"  value={formData.name} placeholder="Name" onChange={handleChange} required />
          <input type="email" name="email"  value={formData.email} placeholder="Email" onChange={handleChange} required />
        </div>
        <div className="form-row-pay">
          <input type="tel" name="phone"  value={formData.phone} placeholder="Phone" onChange={handleChange} required />
          <input type="number" name="adults"  value={formData.adults} placeholder="Number of Adults" onChange={handleChange} required />
        </div>
        <div className="form-row-pay">
          <input type="number" name="children"  value={formData.children} placeholder="Number of Children" onChange={handleChange} required />
          <input
            type="date"
            name="arrivalDate"
            placeholder="Arrival Date"
            value={formData.arrivalDate}
            min={minDate.toISOString().split("T")[0]}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-row-pay">
          <button type="submit">Pay Now</button>
        </div>
      </form>
    </div>
  );
};

export default PayNowForm;
