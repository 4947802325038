import React, { useState, useEffect } from 'react';
import { FiMessageCircle } from 'react-icons/fi';
import DummyForm from '../DummyForm'; 
import './index.css';

const EnquireButton = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);

  const toggleForm = () => {
    setIsFormOpen(!isFormOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500 && !hasScrolled) {
        setIsFormOpen(true);
        setHasScrolled(true); 
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hasScrolled]);

  return (
    <div className="enquire-button-container">
      {!isFormOpen && (
        <button className="enquire-button" aria-label="Enquire Now" onClick={toggleForm}>
          <FiMessageCircle className="enquire-icon" size={24} />
        </button>
      )}
      {isFormOpen && (
        <div>
          <div>
            <DummyForm onClose={toggleForm}/> 
          </div>
        </div>
      )}
    </div>
  );
};

export default EnquireButton;
