import React, { useState } from 'react';
import Sidebar from '../SidebarDashboard';
import HomeDashboard from '../HomeDashboard';
import AllBookings from '../AllBookings';
import AllEnquiries from '../AllEnquiries';
import QuizResponses from '../QuizResponses';
import Navbar from '../NavDashboard';
import AddPackages from '../AddPackages';
import AddWildlifePackages from '../AddWildlifePackages';
import AddMediumBlog from '../AddBlog';
import AddCustomerBlog from '../CustomerBlogs';
import AddQuiz from '../QuizForm';
import './index.css';

const AdminDashboard = () => {
  const [activeComponent, setActiveComponent] = useState('HomeDashboard');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const renderComponent = () => {
    switch (activeComponent) {
      case 'HomeDashboard':
        return <HomeDashboard />;
      case 'AllBookings':
        return <AllBookings />;
      case 'AllEnquiries':
          return <AllEnquiries />;
      case 'QuizResponses':
          return <QuizResponses />;
      case 'AddPackages':
        return <AddPackages />;
      case 'AddWildlifePackages':
          return <AddWildlifePackages />;
      case 'AddMediumBlog':
        return <AddMediumBlog />;
      case 'AddCustomerBlog':
        return <AddCustomerBlog />;
      case 'AddQuiz':
        return <AddQuiz />;
      default:
        return <AddPackages />;
    }
  };

  return (
    <div className="dashboard">
      <Navbar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
      <Sidebar 
        isSidebarOpen={isSidebarOpen} 
        setActiveComponent={setActiveComponent} 
        setIsSidebarOpen={setIsSidebarOpen}
        activeComponent={activeComponent} 
      />
      <div className="content-dashboard">
        {renderComponent()}
      </div>
    </div>
  );
};

export default AdminDashboard;
