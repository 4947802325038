import React, { useEffect, useState } from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import './index.css'; 
import { MdDeleteForever } from "react-icons/md";

const firebaseConfig = {
  apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
  authDomain: "phone-auth-d0a8d.firebaseapp.com",
  projectId: "phone-auth-d0a8d",
  storageBucket: "phone-auth-d0a8d.appspot.com",
  messagingSenderId: "309252587015",
  appId: "1:309252587015:web:3f9dc2505965e66269b4a2"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}


const FormDataComponent = () => {
  const [bookings, setBookings] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [bookingToDelete, setBookingToDelete] = useState(null);

  useEffect(() => {
    const fetchBookings = async () => {
      const bookingsSnapshot = await firebase.firestore().collection('formData').get();
      const bookingsData = bookingsSnapshot.docs.map(doc => {
        const data = doc.data();
        const updates = {};
        if (data.pending === undefined) {
          updates.pending = true;
        }
        if (data.completed === undefined) {
          updates.completed = false;
        }
        if (data.callbackRequested === undefined) {
          updates.callbackRequested = false;
        }
        if (data.notInterested === undefined) {
          updates.notInterested = false;
        }
        if (Object.keys(updates).length > 0) {
          firebase.firestore().collection('formData').doc(doc.id).update(updates);
          Object.assign(data, updates);
        }
        return { id: doc.id, ...data };
      });
      setBookings(bookingsData);
    };

    fetchBookings();
  }, []);

  const handleStatusChange = (id, statusField) => {
    setBookings((prevBookings) =>
      prevBookings.map((booking) => {
        if (booking.id === id) {
          const updatedStatus = !booking[statusField];
          firebase.firestore().collection('formData').doc(id).update({ [statusField]: updatedStatus });
          return { ...booking, [statusField]: updatedStatus };
        }
        return booking;
      })
    );
  };

  const openDeleteModal = (id) => {
    setBookingToDelete(id);
    setIsModalOpen(true);
  };

  const closeDeleteModal = () => {
    setBookingToDelete(null);
    setIsModalOpen(false);
  };

  const confirmDelete = async () => {
    if (bookingToDelete) {
      try {
        await firebase.firestore().collection('formData').doc(bookingToDelete).delete();
        setBookings((prevBookings) => prevBookings.filter(booking => booking.id !== bookingToDelete));
        closeDeleteModal();
      } catch (error) {
        console.error("Error deleting document: ", error);
      }
    }
  };

  return (
    <div className="bookings-container">
      {bookings.map((booking) => (
        <div className="booking-card" key={booking.id}>
          <div className="booking-card-header">
            <h3 className="booking-title">Booking Details</h3>
            <button className="delete-button" onClick={() => openDeleteModal(booking.id)}>
            <MdDeleteForever size={20}/>
            </button>
          </div>

          <div className="booking-details">
            <p><strong>Name:</strong> {booking.name}</p>
            <p><strong>Email:</strong> {booking.email}</p>
            <p><strong>Phone:</strong> {booking.phoneNumber}</p>
            <p><strong>Country:</strong> {booking.country}</p>
            <p><strong>Destination:</strong> {booking.destination}</p>
            <p><strong>Adults:</strong> {booking.adults}</p>
            <p><strong>Children:</strong> {booking.childrens}</p>
            <p><strong>Date:</strong> {booking.date}</p>
            <p><strong>Duration:</strong> {booking.duration}</p>
            <p><strong>Callback Time:</strong> {booking.callbackTime}</p>
          </div>
          <div className="booking-status">
            <label className={`checkbox-container ${booking.pending ? 'red' : 'gray'}`}>
              <input
                type="checkbox"
                checked={booking.pending}
                onChange={() => handleStatusChange(booking.id, 'pending')}
              />
              <span className="custom-checkbox">Pending</span>
            </label>
            <label className={`checkbox-container ${booking.callbackRequested ? 'blue' : 'gray'}`}>
              <input
                type="checkbox"
                checked={booking.callbackRequested}
                onChange={() => handleStatusChange(booking.id, 'callbackRequested')}
              />
              <span className="custom-checkbox">Callback</span>
            </label>
            <label className={`checkbox-container ${booking.completed ? 'green' : 'gray'}`}>
              <input
                type="checkbox"
                checked={booking.completed}
                onChange={() => handleStatusChange(booking.id, 'completed')}
              />
              <span className="custom-checkbox">Completed</span>
            </label>
            <label className={`checkbox-container ${booking.notInterested ? 'black' : 'gray'}`}>
              <input
                type="checkbox"
                checked={booking.notInterested}
                onChange={() => handleStatusChange(booking.id, 'notInterested')}
              />
              <span className="custom-checkbox">Not Interested</span>
            </label>
          </div>
        </div>
      ))}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <p>Are you sure you want to delete this document?</p>
            <div className="modal-buttons">
              <button className="modal-button confirm" onClick={confirmDelete}>Yes</button>
              <button className="modal-button cancel" onClick={closeDeleteModal}>No</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormDataComponent;
