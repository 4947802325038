import React, { useState,useEffect,useRef } from 'react';
import './index.css';
import Navbar from '../Nav';
import SideBar from '../SidebarMenu5';
import { IoIosArrowRoundDown } from "react-icons/io";
import { BsBrightnessHighFill } from "react-icons/bs";
import { IoHomeOutline } from "react-icons/io5";
import AfricaGallery from '../AfricaGallery'
import VideoContainer from '../Youtube';
import {Link } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';

const images1 = [
  '/africa-5/gallery-1/a-5-g-1-1.jpg',
  '/africa-5/gallery-1/a-5-g-1-2.jpg',
  '/africa-5/gallery-1/a-5-g-1-3.jpg',
  '/africa-5/gallery-1/a-5-g-1-4.jpg',
  '/africa-5/gallery-1/a-5-g-1-5.jpg',
  '/africa-5/gallery-1/a-5-g-1-6.jpg',
  '/africa-5/gallery-1/a-5-g-1-7.jpg',
]

const images2 = [
  '/africa-5/gallery-2/a-5-g-2-1.jpg',
  '/africa-5/gallery-2/a-5-g-2-2.jpg',
  '/africa-5/gallery-2/a-5-g-2-3.jpg',
  '/africa-5/gallery-2/a-5-g-2-4.jpg',
  '/africa-5/gallery-2/a-5-g-2-5.jpg',
  '/africa-5/gallery-2/a-5-g-2-6.jpg',
  '/africa-5/gallery-2/a-5-g-2-7.jpg',
]

const images3 = [
  '/africa-5/gallery-3/a-5-g-3-1.jpg',
  '/africa-5/gallery-3/a-5-g-3-2.jpg',
  '/africa-5/gallery-3/a-5-g-3-3.jpg',
  '/africa-5/gallery-3/a-5-g-3-4.jpg',
  '/africa-5/gallery-3/a-5-g-3-5.jpg',
  '/africa-5/gallery-3/a-5-g-3-6.jpg',
  '/africa-5/gallery-3/a-5-g-3-7.jpg',
]

const images4 = [
  '/africa-5/gallery-4/a-5-g-4-1.jpg',
  '/africa-5/gallery-4/a-5-g-4-2.jpg',
  '/africa-5/gallery-4/a-5-g-4-3.jpg',
  '/africa-5/gallery-4/a-5-g-4-4.jpg',
  '/africa-5/gallery-4/a-5-g-4-5.jpg',
  '/africa-5/gallery-4/a-5-g-4-6.jpg',
  '/africa-5/gallery-4/a-5-g-4-7.jpg',
]

const images5 = [
  '/africa-5/gallery-5/a-5-g-5-1.jpg',
  '/africa-5/gallery-5/a-5-g-5-2.jpg',
  '/africa-5/gallery-5/a-5-g-5-3.jpg',
  '/africa-5/gallery-5/a-5-g-5-4.jpg',
  '/africa-5/gallery-5/a-5-g-5-5.jpg',
  '/africa-5/gallery-5/a-5-g-5-6.jpg',
  '/africa-5/gallery-5/a-5-g-5-7.jpg',
  '/africa-5/gallery-5/a-5-g-5-8.jpg',
  '/africa-5/gallery-5/a-5-g-5-9.jpg',
]

const images6 = [
  '/africa-5/gallery-6/a-5-g-6-1.jpg',
  '/africa-5/gallery-6/a-5-g-6-2.jpg',
  '/africa-5/gallery-6/a-5-g-6-3.jpg',
  '/africa-5/gallery-6/a-5-g-6-4.jpg',
  '/africa-5/gallery-6/a-5-g-6-5.jpg',
  '/africa-5/gallery-6/a-5-g-6-6.jpg',
  '/africa-5/gallery-6/a-5-g-6-7.jpg',
  '/africa-5/gallery-6/a-5-g-6-8.jpg',
]

const images7 = [
  '/africa-5/gallery-7/a-5-g-7-1.jpg',
  '/africa-5/gallery-7/a-5-g-7-2.jpg',
  '/africa-5/gallery-7/a-5-g-7-3.jpg',
  '/africa-5/gallery-7/a-5-g-7-4.jpg',
  '/africa-5/gallery-7/a-5-g-7-5.jpg',
  '/africa-5/gallery-7/a-5-g-7-6.jpg',
  '/africa-5/gallery-7/a-5-g-7-7.jpg',
]

const preloadImages = (imageUrls) => {
  imageUrls.forEach((url) => {
    const img = new Image();
    img.src = url;
  });
};

const Africa4 = () => {

  const sec0 = useRef(null);
  const sec1 = useRef(null);
  const sec2 = useRef(null);
  const sec3 = useRef(null);
  const sec4 = useRef(null);
  const sec5 = useRef(null);
  const sec6 = useRef(null);
  const sec7 = useRef(null);

  const scrollHandler = (elemRef) => {
    window.scrollTo({ top: elemRef.current.offsetTop, behavior: 'smooth', block: 'start' });
    toggleMenu();
  };

  const scroll = (elemRef) => {
    window.scrollTo({ top: elemRef.current.offsetTop, behavior: 'smooth', block: 'start' });
  };
  

  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const [activeSection, setActiveSection] = useState(null);

  const handleScroll = () => {
    const sections = document.querySelectorAll('.section-container');

    sections.forEach((section) => {
      const rect = section.getBoundingClientRect();
      if (rect.top <= 100 && rect.bottom >= 300) {
        setActiveSection(section.id);
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const allImages = [...images1, ...images2, ...images3, ...images4, ...images5, ...images6];
    preloadImages(allImages);
  }, []);

  return (
    <>
    <HelmetProvider>
    <Helmet>
        <title>South Africa Group Tour Packages: Explore Wildlife and Scenic Beauty</title>
        <meta
          name="description"
          content="Join our South Africa group tour packages for an unforgettable adventure. Experience
          thrilling safaris, breathtaking landscapes, and vibrant culture with friends and family. Book your
          group getaway today!"
        />
    </Helmet>
    <div className='tour-app'>
      <div className='section-container' ref={sec0}>
        <div className='fixed-image sec' style={{ backgroundImage: 'url(/africa-5/a-1.jpg)'}}>
        </div>
        <div className='content'>
          <SideBar isOpen={isMenuOpen} toggleMenu={toggleMenu}  refs={{ sec0, sec1, sec2, sec3, sec4, sec5, sec6, sec7 }}
          scrollHandler={scrollHandler}/>
          <Navbar toggleMenu={toggleMenu} isOpen={isMenuOpen} />
          <div className='content-flex'>
            <h2>“THE BEAUTY OF AFRICA IS THAT IT IS A CONSTANT REMINDER OF THE POWER AND MYSTERY OF NATURE.”</h2>
            <p>- Unknown</p>
            <IoIosArrowRoundDown size={90} style={{ cursor: 'pointer',marginTop:'50px'}} onClick={() => scroll(sec1)}/>
          </div>
        </div>
      </div>

      <div className='section-container' id='overview' ref={sec1}>
        <div className='fixed-image' style={{ backgroundImage: 'url(/africa-5/a-2-1.jpg)' }}>
        </div>
        <div className='content'>
          <SideBar isOpen={isMenuOpen} toggleMenu={toggleMenu}  refs={{ sec0, sec1, sec2, sec3, sec4, sec5, sec6, sec7 }}
          scrollHandler={scrollHandler}/>
          <Navbar toggleMenu={toggleMenu} isOpen={isMenuOpen} />
          <div className='content-flex-2'>
            <div className={`fixed-header-2 ${activeSection === 'overview' ? 'fixed' : ''}`} >
            <h4 className='section-africa'>Overview</h4>
            <hr style={{width:'100%'}}/>
            <h6>Itinerary at a glance</h6>
            </div>
        <div className='scroll'>
          <p style={{fontWeight:'bold'}}>Day 1</p>
          <p>Upon arrival at Eastgate airport, you will be met and greeted by our driver. He will then transfer you to your first lodge in Sabi Sands Nature reserve.</p>
          <p style={{fontWeight:'bold'}}>Day 1 - 4</p>
          <p>You will spend 3 nights in the beautiful Arathusa Safari Lodge.
          On your first day, you will have a sunset game drive before returning to the lodge on time to freshen up and enjoy a delicious dinner.</p>
          <p>Every day, you will be going on sunrise and sunset game drives, looking for wild animals with your knowledgeable guide and tracker who will answer all your questions and tell your fascinating information.</p>
          <p>On your last day and after your last game drive in Sabi Sands, you will enjoy breakfast at the lodge before being picked up by your driver.
          Arathusa Safari Lodge</p>
          <p>
          3 Nights</p>
          <p style={{fontWeight:'bold'}}>Day 4</p>
          <p>You will be transferred to your second lodge in the Thornybush Nature reserve, which will also be for your exclusive use.</p>
          <p style={{fontWeight:'bold'}}>Day 4 - 8</p>
          <p>The game drives program is similar to the previous lodge's, but this is a different reserve, which your guide and tracker will make you discover with all the passion and expertise to ensure the best possible sightings. Every day is different and nature is unpredictable, and this is part of the thrill of being on safari!
          </p>
          <p>On your last morning, your driver will pick you up from your lodge and transfer you to Eastgate airport on time for your flight. The time will depend on your flight time but you will still be able to enjoy a sunrise game drive.</p>
          <p>Thornybush Game Lodge</p>
          <p>4 nights</p>
          <p style={{fontWeight:'bold'}}>Day 8</p>
          <p>It is sadly time to say goodbye to South Africa, unless you plan to visit Cape Town, which is only a short flight away, for a change of scenery with many unique landmarks, excursions and renown cuisine to impress you.</p>
          <p>Alternatively, you may fly to Victoria Falls in Zimbabwe, one of the seven natural wonders of the world, which is another bucket list destination.</p> 
          <p>We hope you will leave Africa with memories of a lifetime and perhaps even the desire to return, for once you caught the safari bug, it never leaves you!</p>
            </div>
          </div>
        </div>
      </div>

      <div className='section-container' id='map' ref={sec2}>
        <div className='fixed-image' style={{ backgroundImage: 'url(/africa-5/a-3.jpg)' }}> 
        </div>
        <div className='content'>
          <SideBar isOpen={isMenuOpen} toggleMenu={toggleMenu}  refs={{ sec0, sec1, sec2, sec3, sec4, sec5, sec6, sec7 }}
          scrollHandler={scrollHandler}/>
          <Navbar toggleMenu={toggleMenu} isOpen={isMenuOpen} />
          <div className='content-flex-2'>
          <div className={`fixed-header-2 ${activeSection === 'map' ? 'fixed' : ''}`} >
          <h4 className='section-africa'>MAP</h4>
            <hr style={{width:'100%'}}/>
            <h6>Visualize Your Journey</h6>
            </div>
            <div className='scroll'>
             <img src='/africa-5/a-5-map.jpeg' alt='' className='map-2'/>
             <p style={{fontWeight:'bold',marginTop:'10px'}}>Key Dates</p>
             <p>1) Day 1 - 4: Arathusa Safari Lodge, Sabi Sand Nature Reserve</p>
             <p>2) Day 4 - 8: Thornybush Game Lodge, Thornybush Game Reserve</p>
            </div>
          </div>
        </div>
      </div>

      <div className='section-container' id='timbavati' ref={sec3}>
        <div className='fixed-image' style={{ backgroundImage: 'url(/africa-5/a-4-1.jpg)' }}> 
        </div>
        <div className='content'>
          <SideBar isOpen={isMenuOpen} toggleMenu={toggleMenu}  refs={{ sec0, sec1, sec2, sec3, sec4, sec5, sec6, sec7 }}
          scrollHandler={scrollHandler}/>
          <Navbar toggleMenu={toggleMenu} isOpen={isMenuOpen} />
          <div className='content-flex-3'>
          <div className={`fixed-header ${activeSection === 'timbavati' ? 'fixed' : ''}`} >
            <h4 className='section-africa'>Sabi Sand Nature Reserve</h4>
            <hr style={{width:'100%'}}/>
            <h6>Aria Overview</h6>
            </div>
            <div className='scroll-2'>
             <p>The Sabi Sand Nature Reserve, named for the Sabie and Sand Rivers which flow through it, is renowned for its wildlife density and predator interactions; seldom will a visitor not see the Big Five. Although it shares a fenceless boundary with Kruger National Park, much of the wildlife in the Sabi Sand stays more or less in the reserve. Over the years, the animals have become used to the presence of safari vehicles, resulting in close encounters, particularly with the elusive leopards.</p>
             <div className='image-flex' style={{paddingBottom:'5px'}}>
             <img src='/africa-5/a-5-sec-1-1.jpg' alt='' className='map-1'/>
             <img src='/africa-5/a-5-sec-1-2.jpg' alt='' className='map-1'/>
             </div>
             <img src='/africa-5/a-5-sec-1-3.jpg' alt='' className='map'/>
             <p style={{paddingTop:'30px'}}>Roaming packs of African wild dogs, territorial lions, and large herds of elephants are also signature sightings. As well as having a consistently high density of wildlife, the reserve's conservation ethos keeps vehicle numbers to a minimum at wildlife sightings. This offers a more exclusive safari experience while ensuring the welfare of the habituated animals. Being in the private reserve generally affords more flexibility with opportunities to off-road, enjoy night drives and bush walks, all of which make visiting this area a truly unique experience.</p>
            </div>
          </div>
        </div>
      </div>

      <div className='section-container' id='thabamati' ref={sec4}>
        <div className='fixed-image' style={{ backgroundImage: 'url(/africa-5/a-5-2.jpg)' }}> 
        </div>
        <div className='content'>
          <SideBar isOpen={isMenuOpen} toggleMenu={toggleMenu}  refs={{ sec0, sec1, sec2, sec3, sec4, sec5, sec6, sec7 }}
          scrollHandler={scrollHandler}/>
          <Navbar toggleMenu={toggleMenu} isOpen={isMenuOpen} />
          <div className='content-flex-3'>
          <div className={`fixed-header ${activeSection === 'thabamati' ? 'fixed' : ''}`} >
            <h4 className='section-africa'>Arathusa Safari Lodge</h4>
            <hr style={{width:'100%'}}/>
            </div>
            <div className='scroll-2'>
              <h6>Property Overview</h6>
            <p>Arathusa Safari Lodge is unique and highly sought-after by visitors because of its prime location within the Sabi Sand Nature Reserve, adjacent to the unfenced Kruger National Park. Together these two areas comprise one of South Africa’s most incredible and pristinely preserved wilderness sanctuaries. Arathusa offers an exceptional safari experience – expect a high concentration of a variety of game, including the Big Five, as well as frequent leopard sightings. A large waterhole in front of the lodge ensures interesting game and bird viewing from the comfort of the camp.
Slide 1 of 9
</p>
            <AfricaGallery images={images1} className='africa-gallery'/>
            <p >Private luxury accommodations, exceptional service, great value for money, and decadent spa treatments put Arathusa Safari Lodge in a league of its own. Guests can enjoy an alfresco dining experience in the traditional open-air boma while being serenaded by the sounds of Africa’s nightlife. The infinity swimming pool overlooks the waterhole, and is the perfect spot for wildlife viewing while cooling off on a sweltering African summer’s afternoon (though, winter’s warmth is not far behind).</p>
            <VideoContainer videoId="7u_lPHdIC3Y" />
            <hr style={{width:'100%'}}/>
              <h6>Experience & Activities</h6>
              <p>Activities include twice daily safari game drives, guided walks, and bird watching.</p>
              <AfricaGallery images={images2} className='africa-gallery'/>
              <div className='image-flex' >
             <img src='/africa-5/a-5-sec-2-1.jpg' alt='' className='map-1'/>
             <img src='/africa-5/a-5-sec-2-2.jpg' alt='' className='map-1'/>
             </div>
              <p style={{paddingTop:'50px'}}></p>
              <hr style={{width:'100%'}}/>
              <h6>Accommodations</h6>
              <p style={{fontWeight:'500',fontStyle:'italic'}}>Water-Facing Suite</p>
              <p>Eight Water Facing Suites face the waterhole in front of the lodge. Each suite is uniquely designed, with fresh interiors and a focus on light and space. Each has an en-suite bathroom with a shower, as well as a private outdoor shower and veranda. Amenities include a mini bar (stocked with a selection of beverages on request), an electronic safe, hairdryer, air conditioning, direct-dial telephone, mosquito net, and tea/coffee making facilities. In keeping with the traditional natural safari experience, there are no televisions in the suites. Guests are able to connect to wifi in the bar/lounge areas.</p>
              <AfricaGallery images={images3} className='africa-gallery' style={{paddingBottom:'20px'}}/>
              <p style={{paddingTop:'10px'}}>Bush-Facing Suite</p>
              <p>The four Bush-Facing Suites are remotely located within the bush a short distance from the lodge. Each suite is uniquely designed, with fresh interiors and a focus on light and space. Each has an en-suite bathroom with a shower, as well as a private outdoor shower and veranda. Amenities include a mini bar (stocked with a selection of beverages on request), an electronic safe, hairdryer, air conditioning, direct-dial telephone, mosquito net, and tea/coffee making facilities. In keeping with the traditional natural safari experience, there are no TVs in the suites. Guests are able to connect to wifi in the bar/lounge areas.
              Slide 1 of 8
              </p>
              <AfricaGallery images={images4} className='africa-gallery' style={{paddingBottom:'20px'}}/>
              
              <hr style={{width:'100%'}}/>
              <h6 style={{paddingBottom:'20px'}}>Fast Facts</h6>
              <div className='highlights'>
              <BsBrightnessHighFill size={50} style={{marginRight:'50px'}}/>
              <div>
                <p style={{fontWeight:'bold'}}>Highlights</p>
                <p>Located in one of the world’s most abundant natural wilderness areas</p>
                <p>A large waterhole is situated in front of the lodge for interesting game and bird viewing</p>
                <p>Activities include game drives and guided bush walks</p>
              </div>
              </div>
              <div className='highlights'>
              <IoHomeOutline size={30} style={{marginRight:'50px'}}/>
              <div>
                <p style={{fontWeight:'bold'}}>Quick Facts</p>
                <p>12 suites </p>
                <p>Open-air dining room</p>
                <p>Bar</p>
                <p>Lounge</p>
                <p>Boma</p>
                <p>Pool</p>
                <p>Spa</p>
                <p>Waterhole</p>
                <p>Bush walks</p>
                <p>Game drives</p>
                <p>Air conditioning & heating</p>
                <p>Wifi in main areas</p>
              </div>
              </div>
              </div>
          </div>
        </div>
      </div>

      <div className='section-container' id='game' ref={sec5}>
        <div className='fixed-image' style={{ backgroundImage: 'url(/africa-5/a-6.jpg)' }}> 
        </div>
        <div className='content'>
          <SideBar isOpen={isMenuOpen} toggleMenu={toggleMenu}  refs={{ sec0, sec1, sec2, sec3, sec4, sec5, sec6, sec7 }}
          scrollHandler={scrollHandler}/>
          <Navbar toggleMenu={toggleMenu} isOpen={isMenuOpen} />
          <div className='content-flex-3'>
          <div className={`fixed-header ${activeSection === 'game' ? 'fixed' : ''}`} >
            <h4 style={{paddingTop:'50px'}}>Thornybush Game Reserve</h4>
            <hr style={{width:'100%'}}/>
            <h6>Aria Overview</h6>
            </div>
            <div className='scroll-2'>
              <p>Thornybush Game Reserve is part of the Greater Kruger National Park and is famed for its huge variety of wildlife including the Big Five - lion, leopard, elephant, buffalo and rhino. This 34,500 acre private reserve recently dropped fences with its neighbors, allowing animals to move freely across a huge expanse of protected wilderness. Being in the private reserve affords guests an exclusive game viewing experience, with a limited number of lodges and visitors. This allows for intimate, uncrowded encounters with wildlife.</p>
              <div className='image-flex'>
                <img src='/africa-4/a-1-new.jpeg' alt='' className='map-1'/>
                <img src='/africa-4/a-3.jpeg' alt='' className='map-1'/>
             </div>
             <div className='image-flex'>
                <img src='/africa-1/africa-sec-2-4.jpg' alt='' className='map-1'/>
                <img src='/africa-4/a-2.jpeg' alt='' className='map-1'/>
             </div>
             <p>The game density is excellent, especially lion and leopard, and guests are able to get as close to the action as possible. Bush walks and night drives offer an exciting way to encounter wildlife.</p>
            </div>
          </div>
        </div>
      </div>

      <div className='section-container' id='siviti' ref={sec6}>
      <div className='fixed-image' style={{ backgroundImage: 'url(/africa-5/a-7-1.jpg)' }}> 
      </div>
        <div className='content'>
          <SideBar isOpen={isMenuOpen} toggleMenu={toggleMenu}  refs={{ sec0, sec1, sec2, sec3, sec4, sec5, sec6, sec7 }}
          scrollHandler={scrollHandler}/>
          <Navbar toggleMenu={toggleMenu} isOpen={isMenuOpen} />
          <div className='content-flex-3'>
          <div className={`fixed-header ${activeSection === 'siviti' ? 'fixed' : ''}`} >
            <h4 style={{paddingTop:'50px'}}>Thornybush Game Lodge</h4>
            <hr style={{width:'100%'}}/>
            </div>
            <div className='scroll-2'>
              <h6>Property Overview</h6>
            <p>A grande dame in the safari industry, Thornybush Game Lodge was one of the first commercial camps in South Africa and firmly established Thornybush Nature Reserve as a world renowned game-viewing destination in the Greater Kruger Park.
            </p>
            <p>After a recent facelift, the lodge has been given a fresh new look yet retained its classic safari charm. Texture and tone play leading roles – wood, steel, leather and rattan combine with neutral pops of colour and greenery. </p>
            <AfricaGallery images={images5} className='africa-gallery'/>
            <p >On arrival, you’ll start to unwind as you walk down the pathway to the thatched main area overlooking the sandy riverbed. Sit at the fabulous bar and enjoy a harvest table-style lunch as herds of elephant, buffalo, and plains game pass by. Delicious breakfasts are also served here after the morning game drive as sunbirds flit around the aloes and nyalas graze on the lush lawns.</p>
            <p>Private and stylish, the lodge's 16 luxury suites have everything you need, including fluffy slippers and an outdoor shower. The rooms also have large sliding doors so you can lie on your bed in your sarong and look out into the bush.</p>
            <p>In the evenings, dinner is served a la carte or in the boma under the stars. Guests can also enjoy beautiful dinners in the bush. Arriving at an Out of Africa setting, lit by lanterns flickering through the glasses on the tables, is one of the most breathtaking moments on safari and one you'll cherish forever.</p>
            <hr style={{width:'100%'}}/>
              <h6>Experience & Activities</h6>
              <p>The day begins as dawn breaks over the African bush. After an early morning wake-up call, coffee, tea and biscuits are served on the main deck. Soon after, you will depart in an open Land Cruiser with experienced rangers and trackers who will share their extensive knowledge of the bush. You may be invited out of the vehicle to look at tracks, examine plants and follow spoor. Meanwhile, guided walks give guests a better chance of seeing some of the smaller creatures of the bush and an opportunity to learn more about the flora and fauna of the region.</p>
              <AfricaGallery images={images6} className='africa-gallery'/>
              <p >Guests can also embark on a scenic flight into Blyde River Canyon. You will be collected from the lodge and taken for a 1.5 hour helicopter trip to explore one of the largest canyons on earth, with the option to land on the top of Blyde and enjoy a quick picnic. You'll then be taken back to Siviti - just in time to depart for your afternoon bumble. </p>
              <hr style={{width:'100%'}}/>
              <h6>Accommodations</h6>
              <p style={{fontWeight:'500', fontStyle:'italic'}}>Luxury Suites</p>
              <p>Private and stylishly decorated, the Luxury Suites are a joy to spend a night in and difficult to leave. </p>
              <p>The large windows above the bath and giant sliding doors to the deck offer incredible views of the riverbed and any animals that may be wallowing there.</p>
              <p>Each of the 16 Luxury Suites have an en-suite bathroom, indoor and outdoor shower and have been carefully designed and spaced to provide privacy. The elegant finishes and extremely comfortable beds complete the impressive rooms.</p>
              <AfricaGallery images={images7} className='africa-gallery'/>
             
              <hr style={{width:'100%'}}/>
              <h6 style={{paddingBottom:'20px'}}>Fast Facts</h6>
              <div className='highlights'>
              <BsBrightnessHighFill size={70} style={{marginRight:'50px'}}/>
              <div>
                <p style={{fontWeight:'bold'}}>Highlights</p>
                <p>Fresh new look and classic safari charm
                Treatments at the spa with the sounds of the bush in the background</p>
                <p>Private and stylish luxury suites</p>
                <p>Watch herds of elephants, buffalo, and plains game pass by the deck</p>
              </div>
              </div>
              <div className='highlights'>
              <IoHomeOutline size={30} style={{marginRight:'60px'}}/>
              <div>
                <p style={{fontWeight:'bold'}}>Quick Facts</p>
                <p>16 luxury suites</p>
                <p>Kamara House villa (opening May 2023) </p>
                <p>Swimming pool</p>
                <p>Lounge</p>
                <p>Dining area </p>
                <p>Bar</p>
                <p>Deck</p>
                <p>Boma</p>
                <p>Fire pit</p>
                <p>Spa</p>
                <p>Gym</p>
                <p>Library</p>
                <p>Airstrip</p>
                <p>Children all ages welcome</p>
                <p>Weddings</p>
                <p>Lodge shop</p>
                <p>Mobile coverage</p>
                <p>Wifi</p>
              </div>
              </div>
              </div>
          </div>
        </div>
      </div>
      <div className='section-container' id='cost' ref={sec7}>
        <div className='fixed-image' style={{ backgroundImage: 'url(/africa-5/a-8.jpg)' }}>
        </div>
        <div className='content'>
          <SideBar isOpen={isMenuOpen} toggleMenu={toggleMenu}  refs={{ sec0, sec1, sec2, sec3, sec4, sec5, sec6, sec7 }}
          scrollHandler={scrollHandler}/>
          <Navbar toggleMenu={toggleMenu} isOpen={isMenuOpen} />
          <div className='content-flex-3'>
          <div className={`fixed-header ${activeSection === 'cost' ? 'fixed' : ''}`} >
            <h4 className='section-africa'>Costs</h4>
            <hr style={{width:'100%'}}/>
            </div>
            <div className='scroll-2'>
              <p style={{fontWeight:'bold'}}>Group of 20 pax : Min 20 paxs required, USD 5208 per pax</p>
             <p>The quote is based on 20 adults sharing, though should children be part of the group, a rate reduction would apply.</p>
             <p style={{fontWeight:'bold'}}>Notes</p>
             <p>- Accommodation is subject to availability at the time of booking.</p>
             <p>- Children under 12 y/o are not allowed at Arathusa Safari Lodge. </p>
             <p>- Rates quoted are subject to change without prior notice by third party suppliers.</p>
             <p>- Due to increasing fuel prices, the road transfer rates are subject to change without notice.</p>
             <p>This cost includes:</p>
             <p>All transfers to, from Eastgate airport and between lodges.</p>
             <p>Sabi Sands and Thornybush reserves conservation fees</p>
             <p>All meals</p>
             <p>All local drinks (soft & alcoholic drinks)</p>
             <p>Two game drives per full day</p>
             <p>At Arathusa:</p>
             <p>- guided bush walk</p>
             
             <p>This cost does not include: </p>
             <p>International flights</p>
             <p>Internal flights to and from Eastgate</p>
             <p>Medical and Travel insurance</p>
             <p>Tips</p>
             <p>Items of personal nature </p>
             <p>Premium brand drinks</p>
             <p>Anything not mentioned above</p>
    
             <p style={{fontWeight:'bold'}}>Note:</p>
             <p><b>Season:</b> We have a wet season when it is very hot, humid and can see big rains at times from October/November to April. From May until September/October, we have the dry season with morning, evening and night getting colder but the days are very pleasant and warm. July / August is the peak game viewing season as the bush is dry, animals gather around waterholes and are easier to spot. This is also a great season for walking safaris.</p>
             <p><b>Rates:</b> there are set rates throughout the year except at peak seasons, which is usually December and Easter holidays, where no special applies and rates might be higher, it is also a busier time in lodges and the reserves.</p>
             <p style={{fontStyle:'italic', color:'#ccc'}}>Powered by ,<br/> Travel Unbounded</p>
            </div>
            <div className="text-center-africa">
              <Link to="/form">
                <button type="button" className="book-btn">
                  Book Now
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    </HelmetProvider>
    </>
  );
}

export default Africa4;
