import React, { useState } from 'react';
import './index.css';

const SidebarMenu = ({ isOpen, toggleMenu, refs, scrollHandler }) => {
  const [activeSection, setActiveSection] = useState(null);

  const handleScroll = (ref, section) => {
    scrollHandler(ref);
    setActiveSection(section);
  };

  return (
    <div className={`sidebar-menu ${isOpen ? 'open' : 'close'}`}>
      <ul>
        <li 
          onClick={toggleMenu} 
          style={{fontSize: '26px', paddingBottom: "10px", color: "white"}}
        >
          Table Of Contents
        </li>
        <li 
          className={activeSection === 'sec0' ? 'active' : ''} 
          onClick={() => handleScroll(refs.sec0, 'sec0')}
        >
          - COVER PAGE
        </li>
        <li 
          className={activeSection === 'sec1' ? 'active' : ''} 
          onClick={() => handleScroll(refs.sec1, 'sec1')}
        >
          - TRAVEL BRIEF
        </li>
        <li 
          className={activeSection === 'sec2' ? 'active' : ''} 
          onClick={() => handleScroll(refs.sec2, 'sec2')}
        >
          - MAP
        </li>
        <li 
          className={activeSection === 'sec3' ? 'active' : ''} 
          onClick={() => handleScroll(refs.sec3, 'sec3')}
        >
          - THORNYBUSH NATURE RESERVE <br/> Day 1 - 4
        </li>
        <li 
          className={activeSection === 'sec4' ? 'active' : ''} 
          onClick={() => handleScroll(refs.sec4, 'sec4')}
        >
          - SIVITI TIMBAVATI HOMESTEAD <br/> Day 1 - 4
        </li>
        <li 
          className={activeSection === 'sec5' ? 'active' : ''} 
          onClick={() => handleScroll(refs.sec5, 'sec5')}
        >
          - KLASERIA PRIVATE GAME RESERVE <br/> Day 4 - 8
        </li>
        <li 
          className={activeSection === 'sec6' ? 'active' : ''} 
          onClick={() => handleScroll(refs.sec6, 'sec6')}
        >
          - TULELA SAFARI LODGE <br/> Day 4 - 8
        </li>
        <li 
          className={activeSection === 'sec7' ? 'active' : ''} 
          onClick={() => handleScroll(refs.sec7, 'sec7')}
        >
          - COSTS
        </li>
      </ul>
    </div>
  );
};

export default SidebarMenu;
