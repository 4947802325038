import React from 'react';
import './index.css';

const Navbar = ({ isSidebarOpen, setIsSidebarOpen }) => {
  return (
    <nav className="navbar-dashboard">
      <div className="navbar-logo-dashboard">
        <img src='/logo.webp' alt='logo'/>
      </div>
      <button 
        className="hamburger-dashboard" 
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        {isSidebarOpen ? '' : '☰'}
      </button>
    </nav>
  );
};

export default Navbar;
