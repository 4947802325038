import React, { useEffect, useState } from "react";
import "./index.css";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { FaArrowRight } from "react-icons/fa";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
  };
  
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
  

const Blog = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const db = firebase.firestore();
      const blogCollection = await db.collection("blogs").get();

      if (!blogCollection.empty) {
        const blogs = blogCollection.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(), 
        }));

        setBlogs(blogs); 
      } else {
        console.log("No blogs found");
      }
    };

    fetchData();
  }, []);

  return (
    <div className="blog-list">
      {blogs.length > 0 ? (
        blogs.map((blog) => (
          <a
            href={blog.mediumUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="blogs-link"
            key={blog.id}
          >
            <div className="card">
              <img src={blog.imageUrl} alt={blog.title} className="card-image" />
              <div className="card-content">
                <h2 className="card-title">{blog.title}</h2>
                <p className="card-description">{blog.description}</p>
                <p className="arrow-btn">
                  Read More <FaArrowRight />
                </p>
              </div>
            </div>
          </a>
        ))
      ) : (
        <p>Loading blogs...</p>
      )}
    </div>
  );
};

export default Blog;
