import React, { useState } from 'react';
import './index.css';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { toast, Toaster } from "react-hot-toast";

const firebaseConfig = {
    apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
    authDomain: "phone-auth-d0a8d.firebaseapp.com",
    projectId: "phone-auth-d0a8d",
    storageBucket: "phone-auth-d0a8d.appspot.com",
    messagingSenderId: "309252587015",
    appId: "1:309252587015:web:3f9dc2505965e66269b4a2"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const AddBlog = () => {
    const [image, setImage] = useState(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [mediumUrl, setMediumUrl] = useState('');

    const handleImageChange = (e) => {
        if (e.target.files[0]) {
            setImage(e.target.files[0]);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (image) {
            const storageRef = firebase.storage().ref();
            const blogImageRef = storageRef.child(`blogs/${image.name}`);
            await blogImageRef.put(image);
            const blogImageUrl = await blogImageRef.getDownloadURL();

            const db = firebase.firestore();
            await db.collection('blogs').add({
                title,
                description,
                mediumUrl,
                imageUrl: blogImageUrl,
                createdAt: firebase.firestore.FieldValue.serverTimestamp()
            });

            toast.success('Blog added successfully!');
            setImage(null);
            setTitle('');
            setDescription('');
            setMediumUrl('');
        } else {
            toast.error('Please select a blog image');
        }
    };

    return (
        <div className="add-blog-container">
            <Toaster toastOptions={{ duration: 5000 }} style={{ fontStyle: 'Canva Sans', color: 'black' }} />
            <form className="add-blog-form" onSubmit={handleSubmit}>
            <h2 className="add-blog-title">Add Medium Blog</h2>
                <div className="blog-form-group">
                    <label className="blog-form-label">Image:</label>
                    <input type="file" className="blog-form-input blog-file-input" onChange={handleImageChange} required />
                </div>
                <div className="blog-form-group">
                    <label className="blog-form-label">Title:</label>
                    <input 
                        type="text" 
                        className="blog-form-input" 
                        value={title} 
                        onChange={(e) => setTitle(e.target.value)} 
                        required 
                    />
                </div>
                <div className="blog-form-group">
                    <label className="blog-form-label">Description:</label>
                    <textarea 
                        className="blog-form-textarea" 
                        value={description} 
                        onChange={(e) => setDescription(e.target.value)} 
                        required 
                    />
                </div>
                <div className="blog-form-group">
                    <label className="blog-form-label">Medium URL:</label>
                    <textarea 
                        className="blog-form-textarea" 
                        value={mediumUrl} 
                        onChange={(e) => setMediumUrl(e.target.value)} 
                        required 
                    />
                </div>
                <button type="submit" className="blog-submit-button">Add Blog</button>
            </form>
        </div>
    );
}

export default AddBlog;
