import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { Link } from "react-router-dom";
import { MdDateRange, MdAccountCircle } from "react-icons/md";
import './index.css';

const CustomerBlogs = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      const db = firebase.firestore();
      const snapshot = await db.collection("CustomerBlogs").orderBy("createdAt", "desc").get();
      const blogsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBlogs(blogsData);
    };

    fetchBlogs();
  }, []);

  const formatDate = (timestamp) => {
    if (!timestamp) return "";
    const date = timestamp.toDate();
    return date.toLocaleDateString();
  };

  return (
    <div>
      {blogs.length > 0 ? (
        <div>
         <h1 className="home-head text-center mt-5">CUSTOMER BLOGS</h1>
        <div className="blog-list">
          {blogs.map((blog) => (
            <Link to={`/blog/${blog.id}`} key={blog.id} className="card blogs-link">
              <img src={blog.imageUrl} alt={blog.title} className="customer-card-image" />
              <h2 className="card-title">{blog.title}</h2>
              <div className="customer-data">
              <p className="card-date"><MdDateRange size={25}/> {formatDate(blog.createdAt)}</p>
              <p className="card-date"><MdAccountCircle size={25}/> {blog.customerName}</p>
              </div>
            </Link>
          ))}
        </div>
        </div>
      ) : (
        <p></p>
      )}
    </div>
  );
};

export default CustomerBlogs;
