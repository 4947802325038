import React, {useState, useEffect} from 'react';
import Navbar from '../Navbar';
import Footer from '../Contact';
import HomeSlider from '../HomeSlider';
import {Link} from 'react-router-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import './index.css';

const images = [
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/Iceland%2Fpexels-julia-volk-5111058-2.webp?alt=media&token=b5ad7e67-4959-4c7f-aaa4-7c5a59da8988",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/Iceland%2Fpexels-simonmigaj-953182-2.webp?alt=media&token=08162fc1-583d-4604-8b17-c4d0975d34da",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/Iceland%2Fpexels-simonmigaj-1009136-2.webp?alt=media&token=4ac3c764-da3f-4f74-998f-8c9009bd691a",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/Iceland%2Fpexels-evgeny-tchebotarev-1058775-4101555-2.webp?alt=media&token=10b80007-876b-4604-b684-781a035b2c1e",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/Iceland%2Fpexels-yaroslav-shuraev-1553960-2.webp?alt=media&token=a0d4e667-b40b-4e04-b7f4-26009473bd39",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/Iceland%2Fpexels-julia-volk-5111069-2.webp?alt=media&token=99e8ee90-2afb-4d1d-b4c2-88d1cacc789a"
]

const DestinationIceland = () => {
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        const fetchPackages = async () => {
          const db = firebase.firestore();
          try {
            const snapshot = await db.collection("PackageTemplate").get();
            const packageList = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setPackages(packageList);
          } catch (error) {
            console.error("Error fetching packages:", error);
          } 
        };
    
        fetchPackages();
      }, []);

      const icelandPackages = packages.filter(pkg => 
        pkg.destinationName.toLowerCase().includes('iceland')
      );
    
    return (
        <>
             <HelmetProvider>
             <Helmet>
            <title>Iceland South Coast Winter Escape | Glacier Hikes & Northern Lights</title>
            <meta
                name="description"
                content="Discover Iceland’s stunning South Coast. Experience glacier hikes, frozen waterfalls, black sand beaches, and the magical Northern Lights. Explore top destinations like Jökulsárlón Glacier Lagoon, Reynisfjara Beach, and more, all with a personal guide. Book your ultimate Iceland winter adventure today!"
                />
            </Helmet>
            <Navbar/>
            <HomeSlider images={images}/>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-12 col-lg-12 col-xl-12 iceland-container mt-5'>
                        <h1>Discover Iceland: Land of Fire, Ice, and Natural Wonders</h1>
                        <p>Iceland, located in the North Atlantic, is famous for its stunning landscapes, vibrant culture, and unique natural wonders. Known as the "Land of Fire and Ice," this island nation boasts a mesmerizing blend of glaciers, volcanoes, geysers, and hot springs, making it a dream destination for nature enthusiasts and adventure seekers alike.</p>
                        <h2>Geography of Iceland</h2>
                        <p>Iceland's unique geography is shaped by active volcanic and glacial forces. The island features striking landscapes including:</p>
                        <ul>
                            <li><b>Volcanoes:</b> Active volcanoes like Eyjafjallajökull.</li>
                            <li><b>Glaciers:</b> Massive ice fields such as Vatnajökull.</li>
                            <li><b>Waterfalls:</b> Iconic falls like Gullfoss and Seljalandsfoss.</li>
                            <li><b>Lava Fields & Geysers:</b> Explore volcanic remnants and bubbling geysers, especially in the Golden Circle region.</li>
                        </ul>
                        <p>These stunning natural formations are ideal for exploration, with famous spots such as The Blue Lagoon and Thingvellir National Park, a UNESCO World Heritage site.</p>
                        <h2>International Airports in Iceland</h2>
                        <ul>
                            <li><b>Keflavík International Airport (KEF):</b> The main international hub, located near Reykjavik.</li>
                            <li><b>Akureyri Airport (AEY):</b> Serving northern Iceland, this regional airport is perfect for travelers exploring the northern fjords.</li>
                        </ul>
                        <h2>Climate in Iceland</h2>
                        <p>Understanding Iceland's climate will help you plan the perfect trip:</p>
                        <ul>
                            <li><b>Summer (June to August):</b> Mild weather and long days, ideal for outdoor adventures.</li>
                            <li><b>Winter (November to March):</b> Colder, with snow, offering prime conditions to witness the Northern Lights.</li>
                            <li><b>Spring and Fall:</b> Quieter seasons with moderate temperatures, perfect for avoiding the crowds.</li>
                        </ul>
                        <h2>Cultural Experiences in Iceland</h2>
                        <p>Icelandic culture is deeply connected to its natural environment and history:</p>
                        <ul>
                            <li><b>Language:</b> The official language is Icelandic, though English is widely spoken.</li>
                            <li><b>Festivals:</b> Don’t miss events like Reykjavík Culture Night, Iceland Airwaves (a renowned music festival), and the mid-winter feast of Þorrablót.</li>
                            <li><b>Arts & Crafts:</b> Discover Iceland’s traditional woolen goods, ceramics, and the nation’s rich literary tradition, particularly its ancient sagas.</li>
                        </ul>
                        <h2>Visa Formalities</h2>
                        <p>Visitors from most countries can enter Iceland visa-free for short stays. However, travelers from specific nations may require a Schengen visa. For the latest information, visit the <a href='https://island.is/s/utlendingastofnun' target='__blank'>Icelandic Directorate of Immigration</a> website.</p>
                        <h2>Top Tourist Attractions in Iceland</h2>
                        <h3>1. Reykjavik: The Capital of Iceland</h3>
                        <ul>
                            <li><b>Hallgrímskirkja:</b> An iconic church offering stunning panoramic views of Reykjavik.</li>
                            <li><b>Harpa Concert Hall:</b> A striking glass building known for cultural performances.</li>
                            <li><b>Blue Lagoon:</b> The famous geothermal spa located just outside the capital.</li>
                        </ul>
                        <h3>2. The Golden Circle</h3>
                        <ul>
                            <li><b>Thingvellir National Park:</b> Witness the tectonic plates meet in this UNESCO site.</li>
                            <li><b>Geysir Geothermal Area:</b> Home to the famous Strokkur geyser.</li>
                            <li><b>Gullfoss Waterfall:</b> One of Iceland’s most famous and powerful waterfalls.</li>
                        </ul>
                        <h3>3. South Coast Adventures</h3>
                        <ul>
                            <li><b>Seljalandsfoss & Skógafoss:</b> Majestic waterfalls with awe-inspiring backdrops.</li>
                            <li><b>Reynisfjara Beach:</b> A dramatic black sand beach with iconic basalt columns.</li>
                            <li><b>Jökulsárlón Glacier Lagoon:</b> A breathtaking lagoon filled with floating icebergs.</li>
                        </ul>
                        <h3>4. Northern Iceland</h3>
                        <ul>
                            <li><b>Akureyri:</b> Known for its fjords and lush botanical gardens.</li>
                            <li><b>Goðafoss Waterfall:</b> The "Waterfall of the Gods," a must-see in northern Iceland.</li>
                            <li><b>Lake Mývatn:</b> Famous for geothermal activity and rich birdlife.</li>
                        </ul>
                        <h3>5. Westfjords: Iceland’s Hidden Gem</h3>
                        <ul>
                            <li><b>Dynjandi Waterfall:</b> A stunning series of waterfalls cascading down a mountain.</li>
                            <li><b>Hornstrandir Nature Reserve:</b> Remote and perfect for hiking and spotting wildlife.</li>
                        </ul>
                    <h2>Adventure Activities in Iceland</h2>
                    <p>Iceland offers a wide array of activities for thrill-seekers:</p>
                    <ul>
                        <li><b>Trekking & Hiking:</b> Popular routes include the Laugavegur Trail and the Fimmvörðuháls Trek.</li>
                        <li><b>Glacier Hiking:</b> Embark on a glacier hike on Vatnajökull or Snæfellsjökull.</li>
                        <li><b>Whale Watching:</b> Husavik and Reykjavik offer world-class whale-watching experiences.</li>
                        <li><b>Northern Lights:</b> Best viewed during winter in remote areas like Thingvellir or near Akureyri.</li>
                    </ul>
                    </div>
                </div>
            </div>
                <p className='iceland-destination-head mb-3 mt-5'>Discover Iceland Tour Packages</p>
                    <div className="destinations-container mb-5">
                    <div className="best-package-list">
                    {icelandPackages.map((pkg) => (
                        <div key={pkg.id} className="package-list-card mb-3">
                        <img
                            src={pkg.bannerImageUrl}
                            alt={pkg.destinationName}
                            className="list-card-img mb-3"
                        />
                        <div>
                            <p className="title-list mb-3">{pkg.destinationHead}</p>
                            <p className="title-dates-list bold mb-3">{pkg.noOfDays}</p>
                            <p className="title-rate-list bold mb-3">{pkg.cost}</p>
                            <div className="btn-container-list-card">
                            <Link
                                to={`/package/leisure/${pkg.destinationName.replace(/\s+/g, "-")}`}
                                className="blogs-link"
                            >
                                <button className="view-button-list-card">
                                View Itinerary
                                </button>
                            </Link>
                            <Link to="/form" className="blogs-link">
                                <button className="view-button-list-card-book">
                                Book Now
                                </button>
                            </Link>
                            </div>
                        </div>
                        </div>
                    ))}
                    </div>
            </div>
            <Footer/>
            </HelmetProvider>
        </>
    );
}

export default DestinationIceland;
