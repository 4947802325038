import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Contact';
import HomeSlider from '../HomeSlider';
//import {Link} from 'react-router-dom';
//import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import Faq from '../Faq';
import './index.css';


const faqData = [
  {
    question: "1. What is the best time to visit Tanzania?",
    answer: `The ideal time to visit Tanzania depends on your interests:<br />
             <strong>June to October</strong>: Best for the dry season, offering excellent wildlife viewing and the Great Migration in the Serengeti.<br />
             <strong>January to March</strong>: Great for the calving season in the southern Serengeti, when thousands of wildebeest give birth, attracting predators.<br />
             <strong>April and May</strong>: The rainy season, but fewer crowds and lush scenery.`
  },
  {
    question: "2. What are the must-visit national parks in Tanzania?",
    answer: `Tanzania boasts several world-renowned national parks:<br />
             <strong>Serengeti National Park</strong>: Famous for the annual wildebeest migration and incredible predator sightings.<br />
             <strong>Ngorongoro Conservation Area</strong>: Home to the world's largest unbroken caldera with diverse wildlife.<br />
             <strong>Tarangire National Park</strong>: Known for large elephant herds and iconic baobab trees.<br />
             <strong>Lake Manyara National Park</strong>: Notable for tree-climbing lions and flamingos.<br />
             <strong>Gombe Stream National Park</strong>: Best for close encounters with chimpanzees.`
  },
  {
    question: "3. How can I see the Great Wildebeest Migration?",
    answer: `The migration occurs year-round as wildebeest, zebras, and other animals travel across the Serengeti and into Kenya’s Maasai Mara.<br />
             <strong>June-July</strong>: The Grumeti River crossing.<br />
             <strong>July-October</strong>: The Mara River crossing (north Serengeti).<br />
             <strong>January-March</strong>: Calving season in the Ndutu region of southern Serengeti.`
  },
  {
    question: "4. What kind of safaris can I experience in Tanzania?",
    answer: `In Tanzania, you can experience a range of safari types:<br />
             <strong>Game Drives</strong>: The most popular way to experience Tanzania’s wildlife.<br />
             <strong>Walking Safaris</strong>: For a more immersive experience with nature.<br />
             <strong>Hot Air Balloon Safaris</strong>: An incredible way to witness the Serengeti’s vast landscapes.<br />
             <strong>Cultural Safaris</strong>: Visit Maasai villages and experience their traditions.`
  },
  {
    question: "5. Do I need a visa to visit Tanzania?",
    answer: `Most foreign nationals require a visa to enter Tanzania. Single-entry tourist visas can be obtained online through the Tanzania eVisa portal or upon arrival at the main points of entry. Check the visa requirements for your specific nationality before traveling.`
  },
  {
    question: "6. Is Tanzania safe for tourists?",
    answer: `Tanzania is generally considered a safe destination for tourists. However, it is important to:<br />
             - Exercise caution in cities and tourist areas.<br />
             - Avoid displaying valuables.<br />
             - Be mindful of wildlife safety rules during safaris.<br />
             - Follow local regulations and travel advice from guides.`
  },
  {
    question: "7. What should I pack for a safari in Tanzania?",
    answer: `Essentials include:<br />
             - Neutral-colored clothing to blend with the environment.<br />
             - Binoculars and a camera for wildlife viewing.<br />
             - Hat, sunglasses, and sunscreen to protect from the sun.<br />
             - Comfortable walking shoes.<br />
             - Insect repellent.<br />
             - Lightweight rain gear during the rainy season.`
  },
  {
    question: "8. Can I climb Mount Kilimanjaro?",
    answer: `Yes, Tanzania is home to Mount Kilimanjaro, Africa’s highest peak, and climbing it is one of the top adventures in the country. Treks usually take between 5 to 9 days and require a good level of fitness.`
  },
  {
    question: "9. Are there any beach destinations in Tanzania?",
    answer: `Yes, Tanzania has beautiful beach destinations:<br />
             <strong>Zanzibar</strong>: A tropical paradise known for its pristine beaches, rich history, and spice markets.<br />
             <strong>Pemba and Mafia Islands</strong>: Ideal for diving and snorkeling with rich marine life.`
  },
  {
    question: "10. What vaccinations do I need before visiting Tanzania?",
    answer: `Visitors should check with their local health authorities for current recommendations. Common vaccinations include Yellow Fever (mandatory if arriving from certain countries), Hepatitis A & B, Typhoid, and routine vaccinations. Anti-malaria medication is also advised.`
  },
  {
    question: "11. What currency is used in Tanzania, and can I use credit cards?",
    answer: `The official currency is the Tanzanian Shilling (TZS). Major hotels, restaurants, and tour operators often accept credit cards, but it’s advisable to carry cash, especially when visiting remote areas. ATMs are available in major towns and cities.`
  },
  {
    question: "12. What languages are spoken in Tanzania?",
    answer: `The official languages are Swahili and English. English is widely spoken in tourist areas, but learning a few basic Swahili phrases can enrich your travel experience.`
  },
  {
    question: "13. Can I see the Big Five in Tanzania?",
    answer: `Yes, Tanzania is an excellent place to see the Big Five (lion, leopard, elephant, buffalo, and rhino), especially in parks like the Serengeti and Ngorongoro Conservation Area.`
  },
  {
    question: "14. What is the food like in Tanzania?",
    answer: `Tanzanian cuisine includes dishes such as ugali (maize porridge), nyama choma (grilled meat), and various seafood dishes, especially along the coast and in Zanzibar. Be sure to try chapati, samosas, and local fruits.`
  },
  {
    question: "15. Is tipping expected in Tanzania?",
    answer: `Tipping is customary in Tanzania. For safari guides, drivers, and lodge staff, a tip of USD 10-20 per day per person is a good benchmark. In restaurants, a 10% tip is generally appreciated.`
  }
];



const images = [
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/tanzania%2Ftan-1.webp?alt=media&token=b8096cd5-e936-470d-8f2c-53f990babbba",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/tanzania%2Ftan-3.webp?alt=media&token=298f2cc2-788c-49f8-92b3-b06ceafe41f6",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/tanzania%2Ftan-1%20(1).webp?alt=media&token=835aa3ed-7979-4be3-b44f-5421ab700c36",
]

const DestinationTanzania = () => {
    return (
        <>
         <HelmetProvider>
         <Helmet>
            <title>Explore Tanzania - Safari, Wildlife, & Adventure Travel Guide | Travel Unbounded</title>
            <meta
                name="description"
                content="Discover the beauty of Tanzania with Travel Unbounded! Explore thrilling safaris, breathtaking wildlife, serene beaches, and rich culture. Plan your unforgettable journey to Serengeti, Mount Kilimanjaro, Zanzibar, and beyond with our expert tips and travel guides."
                />
            </Helmet>
            <Navbar/>
            <HomeSlider images={images}/>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-12 col-lg-12 col-xl-12 tanzania-container mt-5'>
                      <h1>Tanzania - Cradle of Mother Nature</h1>
                      <p>Tanzania, one of the world's most diverse wilderness destinations, offers an unparalleled combination of flora, wildlife, and breathtaking landscapes. From towering mountains and lush woodlands to vast savannahs and scenic wooded hills, Tanzania's natural beauty captivates millions of travelers each year. Together with Kenya, Tanzania forms the largest terrestrial ecosystem on Earth and is home to over 20% of Africa’s animal population, including more than four million wild animals. Among its most renowned attractions is the spectacular annual wildebeest migration.</p>
                      <h2>The Wildebeest Migration & Calving Season</h2>
                      <p>Every February and March, more than a million wildebeests congregate on the Ndutu Plains, spending over three weeks mating and giving birth. This "calving season" sees the birth of over 8,000 calves per day and stands as one of the world’s most remarkable wildlife spectacles.</p>
                      <p>Tanzania also offers rare sightings of critically endangered East African black rhinos in Ngorongoro and Mkomazi, the legendary elephant tuskers of Tarangire, and flocks of flamingos that color Lake Manyara’s waters—a wildlife experience like no other.</p>
                      <h2>Getting to Tanzania</h2>
                      <p>Travelers to Tanzania require a single-entry visa valid for up to three months for holidays, leisure, health treatment, or other legal activities. To apply for a visa and check countries exempt from visa requirements, please refer to the official Tanzanian immigration website.</p>
                      <h2>National Parks and Conservation Areas</h2>
                      <h3>1. Serengeti National Park</h3>
                      <p>The Serengeti, meaning "The Land of Endless Plains" in the Maa language, is a vast wilderness etched into the memories of all who visit. It boasts rocky outcrops perfect for lion sightings, rivers cutting through the plains, and woodlands punctuating grasslands. Recognized globally as one of the world's most significant ecosystems, the Serengeti promises unforgettable wildlife experiences.</p>
                      <h3>2. Ngorongoro Conservation Area</h3>
                      <p>Ngorongoro is home to the world's largest inactive volcanic caldera, dating back two million years. Its crater, measuring 610 meters deep and covering 260 square kilometers, is an exceptional spot to witness black rhinos, black-maned lions, and more than 30,000 animals, including leopards, cheetahs, wildebeests, and buffalo. With its abundant wildlife and stunning scenery, it is a must-visit for any nature enthusiast.</p>
                      <h3>3. Tarangire National Park</h3>
                      <p>Famous for its annual elephant migration of around 3,000 elephants, Tarangire offers a magical experience. The park's surreal landscape, dotted with massive baobab trees and termite mounds, is also home to the endangered Ashy Starling, greater kudu, and fringe-eared oryx.</p>
                      <h2>Additional Parks to Explore</h2>
                      <ul>
                        <li><b>Gombe Stream National Park:</b> Known for close encounters with chimpanzees, it gained fame thanks to Jane Goodall’s research in the 1960s.</li>
                        <li><b>Mikumi National Park:</b> Often called the "second Serengeti," this park is teeming with lions, giraffes, zebras, and more, set amidst baobab trees and grassy plains.</li>
                        <li><b>Udzungwa Mountains National Park:</b> This lush paradise of waterfalls, rainforest islands, and biodiversity includes four endemic primate species.</li>
                      </ul>
                      <h2>Points of Interest in Dar es Salaam</h2>
                      <ul>
                        <li><b>Mbudya Island:</b> A short boat ride from Dar es Salaam, this serene escape offers crystal-clear waters, delicious seafood, and pristine beaches.</li>
                        <li><b>St. Joseph's Cathedral:</b> The Gothic-style Roman Catholic cathedral is a must-see, especially for Sunday English mass at 8:30 AM.</li>
                        <li><b>Kivukoni Fish Market:</b> For an authentic Dar es Salaam experience, visit this bustling market where fishermen unload their fresh catch.</li>
                        <li><b>National Museum of Dar es Salaam:</b> Discover Tanzania’s history and culture, including unique exhibits like wooden bicycles.</li>
                        <li><b>Coco Beach:</b> Located on the Msasani Peninsula, this lively beach is popular on weekends for its food stalls, live music, and vibrant atmosphere.</li>
                      </ul>
                      <h2>Weather in Tanzania</h2>
                      <p>Tanzania’s climate varies by region, ranging from tropical along the coast to more temperate in the highlands. Visitors are encouraged to pack accordingly for a variety of conditions.</p>
                      <p>Plan your unforgettable journey to Tanzania and immerse yourself in one of the world's greatest wilderness destinations. Explore breathtaking landscapes, witness spectacular wildlife events, and create memories that will last a lifetime!</p>
                      <h2>Frequently Asked Questions:(FAQ's)</h2>
                      <Faq faqData={faqData} />
                    </div>
                </div>
            </div>
            <Footer/>
            </HelmetProvider>
        </>
    );
}

export default DestinationTanzania;
