import React, { Component } from "react";
import Slider from "react-slick";
import "./index.css";

export default class Gallery extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      slidesToScroll: 1,
      slidesToShow: 1,
      arrows: true,
    };

    const { images } = this.props;
    return (
      <div className="africa-carousel-container">
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index}>
              <img
                src={image}
                alt={`gallery ${index}`}
                className="africa-slick-slide"
              />
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}
