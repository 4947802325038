import React, { useState } from 'react';
import './index.css';
import { IoMdMenu } from "react-icons/io";
import { IoShareSocialOutline } from "react-icons/io5";
import { IoCallOutline } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast, Toaster } from "react-hot-toast";
import { MdOutlineMail } from "react-icons/md";
import { CiPhone } from "react-icons/ci";
import { FaWhatsapp } from "react-icons/fa";
import { PiDeviceMobileThin } from "react-icons/pi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from 'react-router-dom';

const Popup = ({ onClose }) => {
  return (
    <div className="popup">
      <div className="popup-content">
        <div className="popup-header">
          <p>Contact Your Travel Consultant</p>
          <RxCross2 onClick={onClose} className='close-btn-popup'/>
        </div>
        <p style={{ fontWeight: 'bold' }}>Travel Unbounded</p>
        <div className="popup-body">
          <div className='pop-row'>
            <MdOutlineMail size={30} style={{ marginRight: '20px' }}/>
            <div className='pop-col'>
              <p className='grey'>Email</p>
              <p>support@travelunbounded.com</p>
            </div>
          </div>
          <div className='pop-row'>
            <CiPhone size={30} style={{ marginRight: '20px' }}/>
            <div className='pop-col'>
              <p className='grey'>Phone</p>
              <p>+91 9845090014</p>
            </div>
          </div>
        </div>
        <div className="popup-body">
          <div className='pop-row'>
            <FaWhatsapp size={30} style={{ marginRight: '20px' }}/>
            <div className='pop-col'>
              <p className='grey'>WhatsApp</p>
              <p>+91 9845090014</p>
            </div>
          </div>
          <div className='pop-row'>
            <PiDeviceMobileThin size={30} style={{ marginRight: '20px' }}/>
            <div className='pop-col'>
              <p className='grey'>Mobile</p>
              <p>+91 9845090014</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Navbar = ({ toggleMenu, isOpen }) => {
  const [copied, setCopied] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const currentUrl = window.location.href;

  const handleCopy = () => {
    toast.success("Link copied to clipboard")
    setCopied(true);
    console.log(copied);
    setTimeout(() => {
      setCopied(false);
    }, 4000);
    closeDropdown();
  };

  const openPopup = () => {
    setShowPopup(true);
    closeDropdown();
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const closeDropdown = () => {
    setShowDropdown(false);
  };

  return (
    <>
      <Toaster toastOptions={{ duration: 4000 }} />
      <nav className="navbar-tour-africa">
        <div className="navbar-hamburger" onClick={toggleMenu}>
          {isOpen ? <RxCross2 size={25} /> : <IoMdMenu size={25} />}
        </div>
        <div className="navbar-logo">
          <img src="/logo.png" alt="Logo" />
        </div>
        <div className="navbar-call">
        <button className='btn-nav-africa' style={{color:'black',background:'lightgreen'}}>Pay Now</button>
          <Link to='/form'><button className='btn-nav-africa'>Book Now</button></Link>
          <CopyToClipboard text={currentUrl} onCopy={handleCopy}>
            <div className="copy-container">
              <IoShareSocialOutline size={20} style={{ marginRight: '10px' }} className='share' />
            </div>
          </CopyToClipboard>
          <IoCallOutline size={20} style={{ marginRight: '10px' }} className='call' onClick={openPopup}/>
        </div>
        <div className="dropdown">
          <div className="navbar-hamburger" onClick={toggleDropdown}>
            <BsThreeDotsVertical size={25} />
          </div>
          <div className={`dropdown-menu ${showDropdown ? 'show' : ''}`}>
            <CopyToClipboard text={currentUrl} onCopy={handleCopy}>
              <div className="dropdown-item"><IoShareSocialOutline size={20} style={{ marginRight: '10px' }} className='share' />Copy Link</div>
            </CopyToClipboard>
            <div className="dropdown-item" onClick={openPopup}><IoCallOutline size={20} style={{ marginRight: '10px' }} className='call'/>Contact Us</div>
            <div style={{ textAlign: 'center', paddingTop: '3px', paddingBottom: '3px' }}>
            <button className='btn-nav-africa' style={{color:'black',background:'lightgreen'}} onClick={closeDropdown}>Pay Now</button>
            </div>
            <div style={{ textAlign: 'center', paddingTop: '3px', paddingBottom: '3px' }}>
              <Link to='/form-africa'><button className='btn-nav-africa' onClick={closeDropdown}>Book Now</button></Link>
            </div>
          </div>
        </div>
      </nav>
      {showPopup && <Popup onClose={closePopup} />}
    </>
  );
};

export default Navbar;
