import React, { useState } from 'react';
import './index.css';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { toast, Toaster } from "react-hot-toast";

const firebaseConfig = {
    apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
    authDomain: "phone-auth-d0a8d.firebaseapp.com",
    projectId: "phone-auth-d0a8d",
    storageBucket: "phone-auth-d0a8d.appspot.com",
    messagingSenderId: "309252587015",
    appId: "1:309252587015:web:3f9dc2505965e66269b4a2"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const AddCustomerBlog = () => {
  const [title, setTitle] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [paragraphs, setParagraphs] = useState([{ heading: '', text: '' }]);

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleParagraphChange = (index, field, value) => {
    const newParagraphs = [...paragraphs];
    newParagraphs[index][field] = value;
    setParagraphs(newParagraphs);
  };

  const addParagraph = () => {
    setParagraphs([...paragraphs, { heading: '', text: '' }]);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleNameChange = (e) => {
    setCustomerName(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (imageFile) {
      const storageRef = firebase.storage().ref();
      const blogImageRef = storageRef.child(`Customerblogs/${imageFile.name}`);
      await blogImageRef.put(imageFile);
      const blogImageUrl = await blogImageRef.getDownloadURL();

      const db = firebase.firestore();
      await db.collection('CustomerBlogs').add({
        title,
        imageUrl: blogImageUrl,
        paragraphs,
        customerName,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      toast.success('Blog added successfully!');
      setImageFile(null);
      setTitle('');
      setParagraphs([{ heading: '', text: '' }]);
      setCustomerName('');
    } else {
      toast.error('Please select a blog image');
    }
  };

  return (
    <>
      <Toaster toastOptions={{ duration: 5000 }} style={{ fontStyle: 'Canva Sans', color: 'black' }} />
      <div className="customer-blog-submit-container">
        <h1 className="customer-blog-title">Write Your Blog</h1>
        <form onSubmit={handleSubmit} className="customer-blog-form">
          <div className="customer-input-group">
            <label>Upload Image:</label>
            <input type="file" onChange={handleImageChange} accept="image/*" />
          </div>
          <div className="customer-input-group">
            <label>Blog Title:</label>
            <input
              type="text"
              value={title}
              onChange={handleTitleChange}
              className="input"
              required
            />
          </div>
          {paragraphs.map((para, index) => (
            <div key={index} className="customer-input-group">
              <label>Heading {index + 1}</label>
              <input
                type="text"
                value={para.heading}
                onChange={(e) => handleParagraphChange(index, 'heading', e.target.value)}
                className="input"
              />
              <label>Paragraph {index + 1}</label>
              <textarea
                value={para.text}
                onChange={(e) => handleParagraphChange(index, 'text', e.target.value)}
                className="customer-blog-textarea"
                rows="4"
              />
            </div>
          ))}
          <button type="button" onClick={addParagraph} className="customer-blog-add-para-btn">
            Add Paragraph
          </button>
          <div className="customer-input-group">
            <label>Name of the creator:</label>
            <input
              type="text"
              value={customerName}
              onChange={handleNameChange}
              className="input"
              required
            />
          </div>
          <button type="submit" className="customer-blog-submit-btn">
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default AddCustomerBlog;
