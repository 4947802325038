import React,{useState, useEffect} from 'react';
import './kids.css';
import Navbar from "../Navbar";
import Contact from "../Contact";
import HorizontalRow from '../HorizontalRow';
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import Faq from '../Faq';
import {Link} from 'react-router-dom';
import Callback from '../Callback';


const faqData = [
  {
    question: "1. Is the safari safe for young children?",
    answer: "Yes! Safety is our top priority. Our guides are experienced in child-friendly tours, and we ensure all safari vehicles and accommodations are secure."
  },
  {
    question: "2. What age group is this safari best suited for?",
    answer: "This safari is ideal for kids aged 8–16, with activities tailored to different age levels."
  },
  {
    question: "3. How many guides and staff will be with the group?",
    answer: "Our group has a dedicated guide-to-child ratio, ensuring that all children receive ample attention and supervision."
  },
  {
    question: "4. What happens in case of a medical emergency?",
    answer: "We are prepared for all situations with medical kits, trained first-aiders, and connections to local healthcare facilities."
  },
  {
    question: "5. Are meals provided? Can dietary preferences be accommodated?",
    answer: "Yes, all meals are included, and we cater to various dietary needs. Please let us know your preferences in advance."
  },
  {
    question: "6. Can an adult accompany the kid on this tour?",
    answer: "Yes, an adult can accompany the kid/s on this tour. The cost for an adult travelling would be USD 1655, Ex Nairobi. The adult has to share the room with the kid/s. The cost provided for the kid and the adult is based on a min 6 kids traveling in one batch and on twin sharing basis. If the adult requires a single occupancy, the cost would be USD 1755, Ex Nairobi. Other Inclusions and Exclusions are applicable as mentioned in the itinerary."
  }

];


const shortItinerary = [
    {
      title: "Day 1 - May 15th, 2025",
      paragraphs: [
        "Arrive in Nairobi, where our Land Cruisers will be ready to pick you up for an exciting drive to Amboseli National Park, renowned for its majestic tuskers, including the famous tusker named Craig. Upon arrival at the resort, enjoy a refreshing lunch before venturing into the park for an afternoon game drive. Discover Amboseli's unique wildlife as you traverse the park, keeping an eye out for elephants, giraffes, and an array of bird species. Return to your camp in the evening to relax. Later, our team leader will host a session detailing Amboseli’s rich history, including conservation efforts that have preserved the habitat for its iconic tuskers. The evening will end with a spirited debate session, dividing the team into two groups. One group will discuss the theme of ‘Patience’ and the other ‘Persistence,’ using examples from wildlife to illustrate these traits. Afterward, enjoy a hearty dinner and retire for the night."
      ]
    },
    {
      title: "Day 2 - May 16th, 2025",
      paragraphs: [
        "Start your day with an early morning game drive, giving you the best chance to see Amboseli's lions, giraffes, zebras, and other fascinating animals in their natural environment. After a relaxing lunch break, continue exploring the park in an afternoon game drive. Amboseli’s landscape, framed by Mount Kilimanjaro, offers countless opportunities for incredible photos. In the evening, gather for dinner back at the resort and unwind. Additionally, a special photography contest will be announced for young participants, offering awards for the best wildlife photography in categories such as Portraits, Animals in Their Environment, and Animal Behavior. Prizes include vouchers, certificates, and an overall winner’s prize of a Maasai Giraffe curio with a cash award. Rest well, ready for more adventures!"
      ]
    },
    {
      title: "Day 3 - May 17th, 2025",
      paragraphs: [
        "Following an early breakfast, embark on a scenic drive to Lake Nakuru, one of Kenya’s most picturesque national parks. Known for its white rhinos, tree-climbing lions, and diverse bird species, Lake Nakuru promises memorable wildlife encounters. Along the journey, you’ll pass through the magnificent Great Rift Valley, a region of historical and geographical significance. Enjoy a packed lunch before continuing your drive to the park, where you’ll have an afternoon game drive. In the evening, check in to a comfortable resort, enjoy a delicious dinner, and take time to relax and prepare for the next day’s explorations."
      ]
    },
    {
      title: "Day 4 - May 18th, 2025",
      paragraphs: [
        "Begin your day with a warm beverage and an early game drive in Lake Nakuru National Park, where the serene morning light enhances the park’s natural beauty. After capturing photos and enjoying the park’s wildlife, return to the resort for a satisfying breakfast. Later, collect packed lunchboxes and drive towards the iconic Maasai Mara, known as one of the best places on Earth to witness wildlife in its natural habitat. Upon arrival, embark on a game drive, taking in Maasai Mara's breathtaking landscapes. Check into your resort in the evening, have dinner, and settle in for a restful night."
      ]
    },
    {
      title: "Day 5 - May 19th, 2025",
      paragraphs: [
        "Spend an exhilarating day immersed in Maasai Mara’s rich wildlife through guided game drives. With a bit of luck, you may witness a thrilling hunt, with cheetahs, lions, or leopards in action. Our guides will take you to the Kenya-Tanzania border, offering ample photo opportunities to capture the stunning landscapes and diverse animal species. In the evening, freshen up and attend a special wildlife photography session led by Chandrashekar Kalyanasundaram, an award-winning photographer. This interactive session will offer insights into wildlife photography techniques and give you a chance to share experiences. End the day with a delightful dinner and some well-deserved rest."
      ]
    },
    {
      title: "Day 6 - May 20th, 2025",
      paragraphs: [
        "After breakfast, visit a traditional Maasai village, where you’ll be welcomed with a vibrant dance performance. The Maasai will demonstrate their unique fire-making techniques and give you a glimpse into their daily lives. This cultural experience provides a deeper understanding of one of the world’s oldest tribes, known for their resilience and distinctive traditions. Later, our guides will drive you back to Nairobi with packed lunchboxes. Upon arrival at Nairobi airport, our team will assist with your departure arrangements. Reflect on your unforgettable journey through Kenya's wilderness as you prepare for your flight back home. End of tour!"
      ]
    }
  ];

    const KenyaKids = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
      };

      const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) { 
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className='kenya-kids-container kenya-animation'>
        <HelmetProvider>
        <Helmet>
        <title>Best Kenya Safari Tour Operators in Bangalore | Travel Unbounded</title>
        <meta
          name="description"
          content='Explore top wildlife safaris with the best Kenya tour operators in Bangalore. Book unforgettable experiences with Travel Unbounded.'
          />
      </Helmet>
        <Navbar/>
        <div>
        <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kenyaKids%2Fk-3.jpg?alt=media&token=e2ad2963-58a0-4e13-9f86-1041101f6fbe' alt='kenya-kids' className='kenya-kids-img' />
        </div>
        <Callback/>
        <Link to='/form' className='link'><div className={`enquire-button-package-home-kenya ${isVisible ? "enquire-left-home-kenya" : ""}`}>
            <span className="button-text blink-text">Get a Quote</span>
        </div></Link>
        <div className='container kenya-kids mb-3 view kenya-animation'>
            <div className='row'>
                <div className='col-12 col-md-12 col-xl-12 col-lg-12 kenya'>
                <h1 className='mt-5'>Kids to Kenya</h1>
                <p style={{fontStyle:'italic', fontWeight:'bold'}}>Dates 15th May 2025 to 20th May 2025</p>
                <HorizontalRow/>
                </div>
                <div className='col-12 col-md-6 col-lg-6 col-xl-6 mt-5 mb-3 kenya'>
                    <h1>Kenya</h1>
                    <HorizontalRow/>
                    <ul>
                      <li>Kenya is often referred as the Mecca for wildlife </li>
                      <li>Kenya is home for the Big 5 - Elephant, Rhino, Leopard, Buffalo and the Lion </li>
                      <li>Kenya has a very diverse landscaped and boasts of very rich ﬂora and fauna </li>
                      <li>Kenya is deﬁnitely one place where the kids can learn lot from mother Nature</li>
                    </ul>
                </div>
                <div className='col-12 col-md-6 col-lg-6 col-xl-6'>
                    <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kenyaKids%2Fk-7.jpg?alt=media&token=2c7071ee-fab4-4acd-a128-de7151395013' alt='kenya-kids-head' className='kenya-kids-head-img'/>
                </div>
                <div className='col-12 col-md-6 col-lg-6 col-xl-6 order-kenya'>
                    <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kenyaKids%2Fk-8.jpg?alt=media&token=27de36ed-459a-4f5c-88db-76ca2c34b49b' alt='kenya-kids-head' className='kenya-kids-head-img'/>
                </div>
                <div className='col-12 col-md-6 col-lg-6 col-xl-6 mt-5 kenya'>
                    <h1>Travel Unbounded</h1>
                    <HorizontalRow/>
                  <p>We are on of the leading, Experiential travel companies headquartered at Bangalore, having our own ofﬁce in Nairobi, Kenya.</p>
                  <p>Since 2014, we have been catering to hundreds of guests to exotic destinations across the World. </p>
                  <p>Having our own operations in East Africa, has made us one of the leading travel companies to this wonderful destination. </p>
                  <p>Our expert team has curated the ‘First of its kind’ Kids tour to Kenya. This is not just a Summer holiday but a journey to learn a lot from Mother Nature</p>
                  </div>
                <div className='col-12 col-md-6 col-lg-6 col-xl-6 order-kenya-2'>
                    <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kenyaKids%2Fk-8.jpg?alt=media&token=27de36ed-459a-4f5c-88db-76ca2c34b49b' alt='kenya-kids-head' className='kenya-kids-head-img'/>
                </div>
                <HorizontalRow/>
                <div className='col-12 col-md-12 col-lg-12 col-xl-12 kenya'>
                    <h1 className='text-center'>5 reasons for the Kids to go on a Kenya safari</h1>
                    <HorizontalRow/>
                    <ul>
                        <li>Unforgettable safari experience </li>
                       <li>Highly educational - the kids will learn a lot about conservation, animal behaviour, ecosystems, culture, history, highly interactive with fun ﬁlled activities. They would learn a lot about wildlife photography, participate in healthy and constructive debates </li>
                       <li>Safe and Guided by the most experienced team</li>
                       <li>Kid friendly accommodations - very safe & hygienic </li>
                       <li>Develops curiosity and wonder , inspires love for Nature & wildlife</li>
                    </ul>
                </div>
                <HorizontalRow/>
                <div className='col-12 col-md-12 col-xl-12 col-lg-12 kenya'>
                <h1 className='text-center'>Itinerary <span className='head-span'>(12 years to 17 years only)</span></h1>
                <HorizontalRow/>
                <div className="itinerary-container mb-5">
                {shortItinerary.map((item, index) => (
                    <div
                    key={index}
                    className={`accordion-item ${activeIndex === index ? "active" : ""}`}
                    >
                    <div className="accordion-header" onClick={() => toggleAccordion(index)}>
                        <h3>{item.title || `Day ${index + 1}`}</h3>
                        <span>{activeIndex === index ? <FaMinus size={20}/> : <FaPlus size={20}/>}</span>
                    </div>
                    {activeIndex === index && (
                        <div className="accordion-body justify">
                        {item.paragraphs.map((paragraph, pIndex) => (
                            <p key={pIndex}>{paragraph}</p>
                        ))}
                        </div>
                    )}
                    </div>
                ))}
                </div>
                </div>
                <HorizontalRow/>
                <div className="col-12 col-md-8 col-lg-8 col-xl-8 mb-4 kenya">
                <img 
                    src="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kenyaKids%2Fk-4.jpg?alt=media&token=3a1c5325-d41a-4704-bb2b-fd186a6daa67" 
                    alt="Full Width" 
                    className="full-width-img" 
                />
            </div>
            <div className="col-12 col-lg-4 col-md-4 col-xl-4 kenya">
                <div className="row d-flex flex-wrap flex-md-column">
                    <div className="col-6 col-lg-12 col-md-12 col-xl-12 mb-4">
                        <img 
                            src="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kenyaKids%2Fk-2.jpg?alt=media&token=e71f6029-9ac9-4165-97e2-d7aed81f4d19" 
                            alt="Half Width" 
                            className="half-width-img" 
                        />
                    </div>
                    <div className="col-6 col-lg-12 col-md-12 col-xl-12">
                        <img 
                            src="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kenyaKids%2Fk-3.jpg?alt=media&token=e2ad2963-58a0-4e13-9f86-1041101f6fbe" 
                            alt="Half Width" 
                            className="half-width-img" 
                        />
                    </div>
                </div>
            </div>

            <HorizontalRow/>
            <div className='col-12 col-md-6 col-lg-6 col-xl-6 mt-5 kenya'>
                    <h1>Inclusions</h1>
                    <HorizontalRow/>
                    <ul className='custom-list'>
                        <li> Meals & Accommodations in safari lodges on full board basis </li>
                        <li>Transport in NON-AC 4x4 Land cruiser as per quote on private basis with pop up roof for easy game viewing </li>
                        <li>Services of our Certiﬁed Driver Guide </li>
                        <li>Drinking water on tap in our vehicle while on Safari</li>
                        <li>Park Entrance Fees as per current rates (Incase next year’s park fees go up, we will advise in good time). </li>
                        <li>Game drives and activities as per itinerary </li>
                        <li>Travel Insurance of USD 50,000 per participant </li>
                        <li>Flying Doctor’s evacuation fee temporary membership </li>
                        <li>Medical kit and goodie bag from Travel Unbounded</li>
                    </ul>
                </div>
                <div className='col-12 col-md-6 col-lg-6 col-xl-6 mt-5 kenya'>
                    <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kenyaKids%2Fk-6.jpg?alt=media&token=1dbc52a3-5126-45af-b116-8ab84e7febfc' alt='inclusion-img' className='inclusion-img'/>
                </div>
                <HorizontalRow/>
                <div className='col-12 col-md-6 col-lg-6 col-xl-6 mt-5'>
                    <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kenyaKids%2Fk-9.jpg?alt=media&token=12d0727d-ecaa-4123-893a-c779c360094c' alt='kenya-kids-head' className='inclusion-img'/>
                </div>
                <div className='col-12 col-md-6 col-lg-6 col-xl-6 mt-5 kenya'>
                    <h1>Exclusions</h1>
                    <HorizontalRow/>
                    <ul>
                        <li> International airfare inclusive of related taxes </li>
                        <li>Visa entry fees </li>
                        <li>Balloon Safari ($ 550 per person) </li>
                        <li>All Optional Activities extras not stated including entry fees at Nairobi </li>
                        <li> Expenses of personal nature such as telecommunications, tips / gratuities,ETC  </li>
                        <li>Laundry services not unless otherwise mentioned </li>
                        <li>Camera, binoculars, bean bags</li>
                        <li> Changes or deviation from the itinerary </li>
                        <li>Any other item/s not speciﬁed above</li>
                    </ul>
                </div>
                <HorizontalRow/>
                <h1 className='text-center'>Cost per kid, Ex Nairobi - USD 1425/-</h1>
                <p className='text-center'>Cost per accompanying Adult, Ex Nairobi - USD 1780 ( 5 kids + 1 adult )</p>
                <HorizontalRow/>
                <div className='col-12 col-md-12 col-xl-12 col-lg-12 kenya'>
                    <img src='https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kenyaKids%2Fk-1.jpg?alt=media&token=c1af3082-3d3f-40eb-b95e-8b6399b8b18f' alt='craig' className='cancellation-img'/>
                </div>
                <HorizontalRow/>
                <h1 className='text-center'>Cancellation Policy</h1>
                <HorizontalRow/>
                <div className='col-12 col-md-12 col-lg-12 col-xl-12 kenya'>
                  <ul>
                    <li> Any non-refundable deposits paid to the hotels or airline tickets purchased, with prior notiﬁcation to the client or agent will not be refunded</li>
                    <li> Where no such deposits have been made, the below applies: </li>
                    <li> Cancellation prior to 60/90 days (depending with season) – only bank charges will be deducted</li>
                    <li> Cancellations received less than 60/90 days prior to departure will be subject to the following</li>
                    <li> Low Season: </li>
                    <li> Less than 60 days 25%</li>
                    <li> Less than 45 days is 50%</li>
                    <li> Less than 15 days is 100%</li>
                    <li> Beyond 60 days – Full refund except bank charges (including service fees) and non-refundable deposits (if any) at the hotels and air tickets</li>
                    <li> Seats would be allocated on ﬁrst come ﬁrst basis </li>
                    <li> All passports must be valid for 6 months after departure and have at least 2 empty pages </li>
                    <li> Visa to Kenya is only available online. Please apply at <a href='http://evisa.go.ke/eligibility.html' target='__blank'>Kenya visa</a></li>
                    <li> Masai Mara park ticket validity has been revised effective 1st July to 6 AM – 6 PM thus disallowing game drives on the date of departure</li>
                    <li> All quotations are based on current Government Park Tariffs. Park fees are subject to change without prior notice.</li>
                  </ul>
                </div>
                <div className='col-12 col-md-12 col-lg-12 col-xl-12'>
                <HorizontalRow/>
                <h1 className='text-center'>Frequently Asked Questions</h1>
                <HorizontalRow/>
                  <Faq faqData={faqData} />
                </div>
            </div>  
          </div>  
          <div className="image-container-kenya-kids">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kenyaKids%2Fk-5.jpg?alt=media&token=ff413256-3ba6-49a3-81c9-bcb337d6cf7b"
              alt="banner"
              className="kenya-kids-img"
            />
            <div className="overlay-text-kenya-kids">
              <p>“Africa changes you forever, like nowhere on Earth. Once you have been there, you will never be the same.” 
              </p>
              <p>–Brian Jackman</p>
            </div>
          </div>
        <Contact/>
        </HelmetProvider>
        </div>
    );
}

export default KenyaKids;
