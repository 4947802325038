import React, { useState, useEffect, useRef } from 'react';
import { RxCross2 } from "react-icons/rx";
import './index.css';

const FloatingButton = ({showEnquireForm}) => {
  const [showButton, setShowButton] = useState(false);
  const [showContactInfo, setShowContactInfo] = useState(false);
  const sliderRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (sliderRef.current) {
        const sliderHeight = sliderRef.current.offsetHeight;
        const scrollPosition = window.scrollY;
        setShowButton(scrollPosition > sliderHeight);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleContactClick = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      window.location.href = "tel:+919141001434";
    } else {
      setShowContactInfo(!showContactInfo);
    }
  };

  return (
    <>
      <div ref={sliderRef}></div>

      {showButton && (
        <div className="floating-button-container">
          {showContactInfo && (
            <div className="contact-info-container-callback">
              <button
                onClick={() => setShowContactInfo(false)}
                className="close-button-callback"
                aria-label="Close contact info"
              >
                <RxCross2 size={20}/>
              </button>
              <p className="contact-number-callback">📱 +91 9141001434</p>
            </div>
          )}
          <div className={`floating-button ${showEnquireForm ? 'transparent' : ''}`}>
            <a
              href="https://wa.me/918792273808"
              target="__blank"
              aria-label="WhatsApp"
              className="link"
            >
              <div className="button-option">
                <span className="icon-callback">💬</span>
                <p>Live Chat</p>
              </div>
            </a>
            <hr />
            <div className="button-option" onClick={handleContactClick}>
              <span className="icon-callback">📞</span>
              <p>Contact</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FloatingButton;
