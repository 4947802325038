import React, {useState, useEffect} from 'react';
import Navbar from '../Navbar';
import Footer from '../Contact';
import HomeSlider from '../HomeSlider';
import {Link} from 'react-router-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import './index.css';

const images = [
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/ranthambore%2Fr-2.webp?alt=media&token=c295ab38-fc6a-477e-9958-c56177423756",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/ranthambore%2Fr-1.webp?alt=media&token=7d9cfa7a-faf5-4f10-99bf-6ca0f7125eea",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/ranthambore%2Fr-4.webp?alt=media&token=3b4018eb-2010-44f2-8fc8-92b2894f3bfd"
]

const DestinationRanthambore = () => {
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        const fetchPackages = async () => {
          const db = firebase.firestore();
          try {
            const snapshot = await db.collection("WildlifePackageTemplate").get();
            const packageList = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setPackages(packageList);
          } catch (error) {
            console.error("Error fetching packages:", error);
          } 
        };
    
        fetchPackages();
      }, []);

      const keralaPackages = packages.filter(pkg => 
        pkg.destinationName.toLowerCase().includes('ranthambore')
      );
    
    return (
        <>
         <HelmetProvider>
         <Helmet>
            <title>Ranthambore National Park: Unleashing Nature's Majesty</title>
            <meta
                name="description"
                content="Embark on a wildlife odyssey at Ranthambore! Encounter tigers, explore ancient ruins, and revel in nature's splendor. Your adventure begins here."
            />
            </Helmet>
            <Navbar/>
            <HomeSlider images={images}/>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-12 col-lg-12 col-xl-12 ranthambore-container mt-5'>
                      <h1>Ranthambore National Park: The Kingdom of the Royal Bengal Tigers</h1>
                      <p>Nestled in the ancient lands of Rajasthan, Ranthambore National Park is a renowned wildlife sanctuary and former hunting grounds for Jaipur’s royalty. The park is famed for its Royal Bengal Tigers and the ancient ruins of forts that now form part of its unique landscape. Its easy access from major cities like Delhi and Jaipur makes it a top choice for wilderness getaways, offering visitors a rare glimpse into the world of India’s wild tigers.</p>
                      <h2>Wildlife at Ranthambore</h2>
                      <p>Ranthambore boasts a diverse ecosystem with over 50 species of mammals, 400 bird species, and a variety of reptiles. The park’s main attraction remains the Bengal Tigers, but it is also home to leopards, deer, black bears, and various species of birds. Its terrain, which includes ravines, grasslands, and water bodies, supports this incredible biodiversity.</p>
                      <h2>Safari Zones and Attractions</h2>
                      <p>The park is divided into 10 safari zones. Each offers unique views of the landscape, from rugged rock formations to lush forests:</p>
                      <ul>
                        <li>Zones 1-5 are popular for tiger sightings and are located closer to Sawai Madhopur.</li>
                        <li>Zones 6-10 are on the opposite side of town, providing equally exciting chances to spot wildlife.</li>
                      </ul>
                      <p>Dhikala Zone is particularly sought after, with its grassy landscapes and frequent sightings. Booking a safari should be done well in advance, especially for the core zones, to ensure an optimal wildlife experience. Many resorts and tour operators assist with safari bookings, making the process convenient for visitors.</p>
                      <h2>Ranthambore Fort and the Trinetra Ganesh Temple</h2>
                      <p>The Ranthambore Fort, a UNESCO World Heritage Site, adds a touch of history to the wilderness. Inside the fort lies the famous Trinetra Ganesh Temple, known as one of Rajasthan’s oldest temples and the only temple where Lord Ganesha is depicted with three eyes. The temple trek provides breathtaking views of the park and is a spiritual highlight for visitors.</p>
                      <p>Local Experiences: Sawai Madhopur Market and Jaipur</p>
                      <p>Visitors can explore the Sawai Madhopur Market to shop for local handicrafts, tiger-themed souvenirs, and enjoy local cuisine. While in Rajasthan, a trip to Jaipur is highly recommended to experience royal palaces, vibrant markets, and authentic Rajasthani cuisine. This makes for a culturally enriching addition to the Ranthambore tour.</p>
                      <h2>Getting to Ranthambore</h2>
                      <p>Ranthambore is easily accessible by road and train from Delhi and Jaipur. International travelers should check the Indian visa requirements well in advance. Most nationalities can apply for an <a href='https://indianvisaonline.gov.in/evisa' target='__blank'>e-Visa</a> online, which simplifies the entry process.</p>
                      <h2>Weather and Best Time to Visit</h2>
                      <p>Ranthambore experiences extreme summers and mild winters. For optimal tiger sightings, visit during summer when the wildlife gathers around water bodies, though winter turns the landscape lush and offers a pleasant experience for trekking and sightseeing.</p>
                    </div>
                </div>
            </div>
                <p className='ranthambore-destination-head mb-3 mt-5'>View Our Ranthambore Packages</p>
                    <div className="destinations-container mb-5">
                    <div className="best-package-list">
                    {keralaPackages.map((pkg) => (
                        <div key={pkg.id} className="package-list-card mb-3">
                        <img
                            src={pkg.bannerImageUrl}
                            alt={pkg.destinationName}
                            className="list-card-img mb-3"
                        />
                        <div>
                            <p className="title-list mb-3">{pkg.destinationHead}</p>
                            <p className="title-dates-list bold mb-3">{pkg.noOfDays}</p>
                            <p className="title-rate-list bold mb-3">{pkg.cost}</p>
                            <div className="btn-container-list-card">
                            <Link
                                to={`/package/wildlife/${pkg.destinationName.replace(/\s+/g, "-")}`}
                                className="blogs-link"
                            >
                                <button className="view-button-list-card">
                                View Itinerary
                                </button>
                            </Link>
                            <Link to="/form" className="blogs-link">
                                <button className="view-button-list-card-book">
                                Book Now
                                </button>
                            </Link>
                            </div>
                        </div>
                        </div>
                    ))}
                    </div>
            </div>
            <Footer/>
            </HelmetProvider>
        </>
    );
}

export default DestinationRanthambore;
